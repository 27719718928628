import {Dialog} from "./common";
import {getJsDateFromExcel, getExcelDateFromJs} from "../mathematics/methods";
import {isDateInvalid} from "../operations/formats";
import {getDateEditFormat} from "../operations/formats";
import {getType} from "../operations/types";
import {find} from "../helpers/array";

export const action = "conditional-format";

function getConditionStyle(data){
	return data.map(item => {
		item.id = item.css;
		return item;
	});
}

export class DialogBox extends Dialog{
	_rulesToArr(){
		const conditions = this.view.conditions.handlers;
		return [
			{
				value: webix.i18n.spreadsheet.labels["conditional-common"],
				disabled:true,
				$css:"webix_ssheet_cformats_type"
			},
			...conditions.date, //number rules equal to date
			{
				value: webix.i18n.spreadsheet.labels["conditional-text"],
				disabled:true,
				$css:"webix_ssheet_cformats_type"
			},
			...conditions.text
		].map(rule => {
			if(!rule.value)
				rule.value = webix.i18n.filter[ rule.id ];
			return rule;
		});
	}

	_getCondition() {
		const view = this.view;
		const values = this._getDatalayout().getValue();

		const data = [];
		values.forEach(v => {
			const item = [v.condition];
			if (v.condition != "between" && v.condition != "notBetween")
				item.push(this._normalizeValue(v.value, v.condition));
			else
				item.push([
					this._normalizeValue(v.value, v.condition),
					this._normalizeValue(v.value2, v.condition)
				]);

			item.push(v.style);
			for (let i=0; i<item.length; i++){
				if (item[i] === "" || (webix.isArray(item[i]) && item[i].indexOf("") != -1))
					return false;
			}
			data.push(item);
		});

		view.callEvent("onConditionSet", [data]);
	}

	_safeInt(a){
		const num = parseFloat(a);
		if (num == a) return num;
		return a;
	}

	_setCondition() {
		const view = this.view;
		const collection = view.conditions.get(this.cell.row, this.cell.column);

		const data = [];
		if (collection) {
			collection.forEach(item => {
				const values = {};
				values.condition = item[0];
				if (webix.isArray(item[1])) {
					values.value = this._formatValue(item[1][0], values.condition);
					values.value2 = this._formatValue(item[1][1], values.condition);
				} else
					values.value = this._formatValue(item[1], values.condition);

				values.style = item[2];
				data.push(values);
			});
		}

		while (data.length < 3)
			data.push({ });

		this._getDatalayout().setValue(data);
	}

	_getDatalayout(){
		return this.$dialog.getBody().getChildViews()[0].getChildViews()[1].getBody();
	}

	_formatValue(value, condition){
		if(!(condition && this._isNotDateRule(condition))){
			if(this.editFormat && value !== "" && value[0] != "="){
				value = getJsDateFromExcel(value);
				return this.editFormat(value);
			}
		}
		return value.toString();
	}

	_normalizeValue(value, condition){
		if(!this._isNotDateRule(condition)){
			if(this.editFormat){
				if(isNaN(value)){
					if(value[0] != "="){
						const parser = webix.Date.strToDate( webix.i18n.spreadsheet.formats.parseDateTime );
						value = parser(value);
						value = isNaN(value) ? "" : getExcelDateFromJs(value);
					}
				}
				else
					value = isDateInvalid(value) ? "" : value;
			}
			else
				value = this._safeInt(value, 10);
		}
		return value;
	}

	_isNotDateRule(condition){
		if(condition == "equal" || condition == "notEqual")
			return false;
		return find(this.view.conditions.handlers.text, obj => obj.id == condition);
	}

	okClick(){
		this._getCondition();
		this.close();
	}

	$show(){
		this.cell = this.view.getSelectedId();
		if(!this.cell)
			return false;

		const type = getType(this.view, this.cell.row, this.cell.column);

		if(type == "date")
			this.editFormat = getDateEditFormat(this.view, this.cell.row, this.cell.column);

		this.$dialog.queryView("text", "all").forEach(view => {
			view.define({placeholder: type == "date" ? this._formatValue(45000) : ""});
			view.refresh();
		});

		this.view.$handleSelection = (st, end, a, b)=>{
			if (this.activeValue && a == b){
				this.activeValue.setValue("="+a);
			}
			return false;
		};
		this._setCondition();
	}

	$hide(){
		this.view.$handleSelection = null;
		delete this.editFormat;
		this._getDatalayout().clearAll();
	}

	getRows(data){
		const dialog = this;
		return {
			view: "form",
			padding: 0,
			borderless: true,
			css:"webix_ssheet_cformats",
			elements: [{
				margin: 10,
				padding: { right: 4 },
				cols: [
					{
						view:"richselect",
						name: "style",
						width:120,
						placeholder: webix.i18n.spreadsheet.labels["conditional-style"],
						css: "webix_ssheet_cformat_select",
						suggest: {
							padding:0,
							borderless: true,
							css: "webix_ssheet_cformat_list",
							body: {
								template(obj) {
									const css = "webix_ssheet_cformat "+obj.css;
									return `<div class="${css}">${obj.name}</div>`;
								},
								data: data
							}
						}
					},
					{
						view:"richselect",
						width: 150,
						name: "condition",
						placeholder: webix.i18n.spreadsheet.labels["conditional-operator"],
						on: {
							onChange: function(newv) {
								const elements = this.getFormView().elements;

								if (dialog.editFormat){
									if (dialog._isNotDateRule(newv))
										elements.value.define({placeholder: ""});
									else {
										const value = dialog._normalizeValue(elements.value.getValue(), newv);
										elements.value.setValue(dialog._formatValue(value, newv));
										elements.value.define({placeholder: dialog._formatValue(45000)});
									}
									elements.value.refresh();
								}

								if (newv == "between" || newv == "notBetween")
									elements.value2.show();
								else
									elements.value2.hide();
							}
						},
						suggest:{
							view: "ssheet-form-suggest",
							body:{
								data: this._rulesToArr()
							}
						}
					},
					{
						cols: [
							{
								view: "text",
								on: {
									onFocus: (current_view)=>{
										this.activeValue = current_view;
									},
									onBlur: (current_view)=>{
										let value = current_view.getValue();
										if(value !== ""){
											const elements = current_view.getFormView().elements;
											const condition = elements.condition.getValue();
											value = this._normalizeValue(value, condition);
											current_view.setValue(this._formatValue(value, condition));
										}
									}
								},
								name: "value"
							},
							{
								view: "text",
								name: "value2",
								on: {
									onFocus: (current_view)=>{
										this.activeValue = current_view;
									},
									onBlur: (current_view)=>{
										let value = current_view.getValue();
										if(value !== ""){
											const elements = current_view.getFormView().elements;
											const condition = elements.condition.getValue();
											value = this._normalizeValue(value, condition);
											current_view.setValue(this._formatValue(value, condition));
										}
									}
								},
								hidden: true
							}
						]
					},
					{ view:"icon", css:"webix_ssheet_cformat_icon", icon:"wxi-trash", click: vid => {
						const form = webix.$$(vid).getFormView();
						const dlayout = this._getDatalayout();
						dlayout.getValue();			// save local changes before rendering

						const index = dlayout.getChildViews().indexOf(form);
						dlayout.remove(dlayout.getIdByIndex(index));

						if (dlayout.count() < 3) dlayout.add({ });
					}}
				]}
			]};
	}

	$init(){
		const margin = webix.skin.$active.layoutMargin.form;
		return {
			view: "ssheet-dialog",
			head: webix.i18n.spreadsheet.labels["conditional-format"],
			position: "center",
			width: 770,
			move: true,
			margin,
			body: { margin:0, rows:[
				{
					height: 36,
					padding: { left: 12 },
					cols: [
						{ view:"label", width: 131, label: webix.i18n.spreadsheet.labels.display },
						{ view:"label", width: 161, label: webix.i18n.spreadsheet.labels.condition },
						{ view:"label", label: webix.i18n.spreadsheet.labels.value },
						{ view:"icon", css:"webix_ssheet_cformat_icon", icon:"wxi-plus-circle",
							click: () => {
								const dlayout = this._getDatalayout();
								dlayout.getValue();			// save local changes before rendering
								dlayout.add({ });
							},
						},
						{ width: 4 + webix.env.scrollSize }
					]
				},
				{
					view: "scrollview",
					maxHeight: webix.skin.$active.inputHeight * 3 + 4,
					borderless: true,
					scroll: "y",
					body: {
						view: "datalayout",
						padding: 0,
						borderless: true,
						rows: [ this.getRows(getConditionStyle(this.view.config.conditionStyle)) ]
					}
				}
			]},
			on: {
				onSaveClick: () => this.okClick(),
				onCancelClick: () => this.close()
			}
		};
	}
}