import {getFormatName, checkFormat, isDateInvalid, getFormatFromData, getFormatSource} from "./formats";
import {getExcelDateFromJs} from "../mathematics/methods";
import {getMathFormat} from "../mathematics/parser";

export function init(view){
	view.attachEvent("onCellChange", function(row, col, value, page){
		if(page == this.getActiveSheet() && !this._setType){
			const formatName = getFormatName(this, row, col);
			const cell = {
				row,
				col,
				value,
				fmt: formatName ? {fmt: getFormatSource(formatName)} : null
			};
			setType(this, cell);
		}
	});

	view.attachEvent("onBeforeFormatChange", function(row, col, formatName){
		if(!this._setType){
			const cell = {
				row,
				col,
				value: view.getCellValue(row, col),
				fmt: {fmt:getFormatSource(formatName)}
			};
			setType(this, cell, true);
		}
	});

	view.attachEvent("onAction", function(action, values){
		if(action == "dropdown" && values.newValue && values.newValue.editor == "excel_date"){
			const format = getFormatName(this, values.row, values.column);
			if(getFormatType(format) != "date")
				this.setFormat(values.row, values.column, webix.i18n.spreadsheet.formats.dateFormat);
		}
	});
}

export function loadCell(view, data, item){
	const fmt = getFormatFromData(data, item);
	const cell = {
		value: item[2],
		fmt: fmt ? {fmt} : null,
		type: item[4]
	};
	setType(view, cell);
	return cell;
}

function getExpectType(view, cell, parseDate, isFormula, formatType, style){
	//date based math
	let type = "string";
	if(!webix.isUndefined(cell.value)){
		if(isFormula){
			type = "number";

			if(/^=(now|date|time)\(/i.test(cell.value))
				type = "date";
			else if(/^=(sparkline|image)\(/i.test(cell.value))
				type = "string";
		}
		else if(!isNaN(cell.value))
			type = "number";
		else if(formatType != "string" && parseDate){
			//if user types a date string, or it comes from Excel import
			//set date type and convert string to Excel date
			const parser = webix.Date.strToDate( webix.i18n.spreadsheet.formats.parseDateTime );
			const value  = parser(cell.value);
			if(value != "" && !isNaN(value)){
				//change cell style - value changes once (some value -> 35767.5)
				//change cell value - value changes twice (some value -> "12/03/97" -> 35767.5)
				//so ignore undo for cell value change
				if(style)
					dateToExcel(view, cell, value);
				else
					view.ignoreUndo(() => dateToExcel(view, cell, value));

				type = "date";
			}
		}
	}
	return type;
}

function dateToExcel(view, cell, date){
	date = getExcelDateFromJs(date);
	cell.value = date;

	if(!(view._loading_data || view._page_value_set))
		view.setCellValue(cell.row, cell.col, date);
}

function checkType(type, expectType, formatType, isFormula, value){
	type = type || expectType;

	//strings will always have string type regardless of format
	if(expectType == "string")
		type = "string";
	else if(formatType)
		type = formatType;

	if(type == "date" && !isFormula && isDateInvalid(value))
		type = "number";

	return type;
}

function getFormatType(format){
	let formatType = format ? "number" : null;
	if(checkFormat(format, "string"))
		formatType = "string";
	else if(checkFormat(format, "date"))
		formatType = "date";
	return formatType;
}

export function getType(view, row, column, page){
	page = page ? view._mData.getPage(page) : view._mPage;
	const item = page.getCell(row-1, column-1);
	return (item && item.type) || null;
}

function setType(view, cell, style){
	const parseToStore = !(cell.row && cell.col);

	//prevent type change during value or format change
	view._setType = true;

	const isFormula = typeof cell.value == "string" && cell.value[0] == "=";
	const parseDate = (!style || cell.fmt == getFormatSource("date")) && (!view._loading_data || view._page_value_set || cell.type == "date");

	let mathFormat;
	if(!parseToStore)
		mathFormat = isFormula && !cell.fmt ? getMathFormat(view, cell.value) : null;

	const formatType = getFormatType(cell.fmt || mathFormat);
	const expectType = getExpectType(view, cell, parseDate, isFormula, formatType, style);

	cell.type = checkType(cell.type, expectType, formatType, isFormula, cell.value);

	if(!parseToStore){
		const item = view._mPage.getCell(cell.row-1, cell.col-1, true);
		item.$format = formatType == "string" ? 1 : 0;
		updateCell(view, cell, expectType, formatType, mathFormat);
		item.type = cell.type;
	}

	delete view._setType;
}

function updateCell(view, cell, expectType, formatType, mathFormat){
	const row = cell.row;
	const col = cell.col;
	const isString = typeof cell.value == "string";

	const isFormula = isString && cell.value[0] == "=";
	const cellEditor = view.getCellEditor(row, col);

	//update cell value if set number format (5e5 -> 500000, 005 -> 5)
	if(!(view._loading_data || view._page_value_set) && cell.type === "number" && isString && !isFormula && cell.value !== "")
		view.setCellValue(row, col, cell.value*1);

	if(mathFormat)
		view.setFormat(row, col, mathFormat.fmt);
	else if(cell.type == "date"){
		// add default date format
		if(formatType != "date"){
			const isTime = isFormula && cell.value.toUpperCase().indexOf("=TIME(") == 0;
			const locale = webix.i18n.spreadsheet.formats;
			const format = isTime ? locale.timeFormat : locale.dateFormat;
			view.setFormat(row, col, format);
		}

		//set date editor for cells with date type
		if(!isFormula && !cellEditor)
			view.setCellEditor(row, col, {editor: "excel_date"});
	}
	else if(formatType != "string" && cell.type == "string" && expectType != "string"){
		//force text format for numbers typed as strings
		view.setFormat(row, col, "@");
	}

	//remove date editor
	if((cell.type != "date" || isFormula) && cellEditor && cellEditor.editor == "excel_date")
		view.setCellEditor(row, col, null);
}
