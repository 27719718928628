import {addMethod} from "@xbs/muon";

export function init(view){
	const math = {
		IMAGE: url => `<img class="webix_ssheet_cimage" src="${webix.template.escape(url)}">`,
		HYPERLINK: (url, text) => {
			const escape = webix.template.escape;
			let attrs;

			if(url[0] == "#")
				attrs = `href="javascript:void(0);" class="webix_ssheet_ref" data-ref="${escape(url.substr(1))}"`;
			else
				attrs = `target="blank" href="${escape(url)}"`;

			return `<a ${attrs}>${escape(text || url)}</a>`;
		},
		SPARKLINE: (arr, type, color1, color2) => {
			const config = { type, color: color1, negativeColor:color2, paddingX:2, paddingY:2 };

			if(type == "donut")
				webix.extend(config, {type:"pie", donut:1}, true);
			else if(type == "barH")
				webix.extend(config, {type:"bar", horizontal:1}, true);

			for(let i = 0 ; i < arr.length; i++)
				arr[i] = arr[i]||0;

			const tableConfig = view.$$("cells").config;

			//[FIXME] we do not know from which cell the method was called, so this is not the real width and height
			const isCircle = config.type == "pie" || type == "radar";
			const width = isCircle ? 100 : tableConfig.columnWidth;
			const height = isCircle ? 100 : tableConfig.rowHeight;
			return webix.Sparklines.getTemplate(config)(arr, { width, height });
		}
	};

	const keys = Object.keys(math);
	keys.forEach((key, i) => addMath(view, key, math[key], i == keys.length - 1));
}

export function addMath(view, name, handler, sort){
	name = name.toUpperCase();
	addMethod(name, handler);
	if(view.$$("liveEditor")){
		const sid = view.$$("liveEditor").config.suggest;
		const list = webix.$$(sid).getList();
		if(!list.exists(name)){
			list.add({id:name, value:name});
			if(sort)
				list.sort("value", "asc", "string");
		}
	}
}

export function getJsDateFromExcel(excelDate) {
	const date = new Date(Math.round((excelDate - 25569) * 86400 * 1000));
	return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
}

export function getExcelDateFromJs(jsDate) {
	const returnDateTime = 25569 + ((jsDate.getTime() - (jsDate.getTimezoneOffset() * 60000)) / (86400 * 1000));
	return returnDateTime.toString();
}