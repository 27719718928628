import group from "./undo_group";
import {pasteCondition} from "../operations/conditional_formats";
import {pasteDropdown} from "../operations/dropdown";
import {pasteFilter} from "../operations/filter";
import {pasteSpan} from "../operations/spans";

let styledata, clipdata, origin, cutted, updateFilters, specialPaste;

export function init(view){
	const clipboard = view.config.clipboard;
	const browserClipboard = navigator.clipboard;

	if(clipboard === false)
		return;

	webix.clipbuffer.init();
	const buffer = document.querySelector(".webix_clipbuffer");

	const keyC = 67; //keyboard 'c' code
	const keyX = 88; //keyboard 'x' code
	const grid = view.$$("cells");

	grid.attachEvent("onAfterAreaAdd", () => _sel_to_clip(view, buffer));

	grid.attachEvent("onKeyPress", (code, e) => {
		if((code === keyC || code === keyX) && (e.ctrlKey || e.metaKey) && grid.getSelectedId()){
			cutted = (code === keyX)*1;
			setClipboard(view, buffer);
		}
	});

	view.attachEvent("onCommand", action => {
		if(action.id.indexOf("paste") != -1){
			specialPaste = action.id.split("-")[1];

			if(browserClipboard && browserClipboard.readText){
				browserClipboard.readText()
					.then(text => {
						webix.clipbuffer.set(text);
						_clip_to_sel(view, grid, buffer.value);
					})
					.catch(()=> {
						_clip_to_sel(view, grid, clipdata);
					})
					.finally(()=> {
						specialPaste = null;
					});
			}
			else {
				_clip_to_sel(view, grid, clipdata);
				specialPaste = null;
			}
		}
		else if(action.id == "copy" || action.id == "cut"){
			cutted = (action.id == "cut")*1;
			setClipboard(view, buffer, browserClipboard, true);
		}
	});

	if (!view.config.readonly) {
		grid.attachEvent("onPaste", text => {
			_clip_to_sel(view, grid, text);
		});
	}
}

function setClipboard(view, buffer, browserClipboard, menu){
	const grid = view.$$("cells");
	styledata = _get_sel_style(view, grid);

	if(menu && browserClipboard && browserClipboard.writeText)
		browserClipboard.writeText(buffer.value);

	clipdata = buffer.value;
	origin = grid.getSelectArea();

	if(cutted && Object.keys(grid._ssFilters).length !== 0)
		updateFilters = allFiltersInZone(grid._ssFilters, origin, view.config.columnCount);
}

function _sel_to_clip(view, buffer){
	const grid = view.$$("cells");
	if(grid.getSelectedId())
		webix.delay(()=>{
			const data = [];
			grid.mapSelection(function(value, row, col, row_ind) {
				value = _updateDelimiter(view._mPage.getValue(row-1, col-1), view.config);
				if (!data[row_ind]) data[row_ind] = [];
				data[row_ind].push(value);
			});
			const clipdata = webix.csv.stringify(data, grid.config.delimiter);

			if (grid.getEditor()){
				buffer.value = clipdata;
			} else {
				buffer.focus();
				webix.clipbuffer.set(clipdata);
			}
			webix.UIManager.setFocus(grid);
		});
}

function _updateDelimiter(value, config) {
	const format = getClipboardNumberFormat(config);

	if(webix.rules.isNumber(value) && format)
		value = webix.Number.numToStr(format)(value);

	return value;
}

function _clip_to_sel(view, grid, text){
	if(!text)
		text = "";

	var leftTop = grid.getSelectArea();
	if (!leftTop) return;
	var start = leftTop.start;
	var fromSheet = text === clipdata;

	var data = fromSheet ? styledata : webix.csv.parse(text, grid.config.delimiter);
	if(!fromSheet)
		data = _changeDelimiter(data, view.config);

	var translate = { id:"move", column:0, row: 0, cut:cutted };
	if (fromSheet){
		translate.column = start.column - origin.start.column;
		translate.row = start.row - origin.start.row;
	}

	if(specialPaste || !fromSheet){
		cutted = 0;
		updateFilters = 0;
	}

	group.set(function(){
		if (updateFilters)
			view.removeFilters();

		_add_row_col(view, start, data);

		if (cutted === 1) {
			for (let row = origin.start.row; row <= origin.end.row; row++) {
				for (let column = origin.start.column; column <= origin.end.column; column++) {
					view.setCellValue(row, column, null);
					view.setStyle(row, column, null);
				}
			}
		}

		const colCount = leftTop.end.column - start.column + 1;
		const rowCount = leftTop.end.row - start.row + 1;

		const dataRowCount = data.length;
		const dataColCount = data[0].length;

		if (!updateFilters && (rowCount > dataRowCount || colCount > dataColCount)) {
			for(let r = 0, ri = 0; r < Math.max(rowCount, dataRowCount); r++, ri++){
				if(ri == data.length) ri = 0;
				for (let c = 0, ci = 0; c < Math.max(colCount, dataColCount); c++, ci++){
					if(ci == data[0].length) ci = 0;

					const subtrans = {
						id: "move",
						column: translate.column + Math.floor(c/dataColCount) * dataColCount,
						row: translate.row + Math.floor(r/dataRowCount) * dataRowCount,
						cut: cutted
					};

					_clipboardToTable(view, start.row + r, start.column + c, data[ri][ci], fromSheet, subtrans);
				}
			}
		} else {
			grid.mapCells(
				start.row,
				start.column,
				dataRowCount,
				null,
				function(value, row, col, row_ind, col_ind) {
					if (data[row_ind] && data[row_ind].length > col_ind) {
						var cdata = data[row_ind][col_ind];
						_clipboardToTable(view, row, col, cdata, fromSheet, translate);
					}
				},
				true
			);
		}

		if (cutted === 1){
			//cut and cleared
			cutted = 2;
			updateFilters = false;
		}
	});
	
	view.refresh();
}

function _clipboardToTable(view, row, col, cdata, fromSheet, translate){
	let newValue = cdata;
	const setValue = !specialPaste || specialPaste == "values" || specialPaste == "formulas";

	if (typeof cdata === "object"){
		if(setValue){
			if (cdata.math && specialPaste != "values"){
				if(translate.cut)
					newValue = cdata.math;
				else
					newValue = view._mData.getStore().transposeMath(cdata.math, translate.row, translate.column);
			}
			else
				newValue = cdata.text;
		}

		if(cdata.style && cdata.style.props && (!specialPaste || specialPaste == "styles")){
			const style = view.addStyle(cdata.style.props);
			view.setStyle(row, col, style);
		}

		const extra = cdata.extra;
		if(extra){
			if(extra.condition && (!specialPaste || specialPaste == "conditions"))
				pasteCondition(view, extra, row, col, cutted);
			if(extra.dropdown && !specialPaste)
				pasteDropdown(view, extra, row, col, cutted, translate);
			if(extra.filter && updateFilters)
				pasteFilter(view, extra, row, col, cutted, translate);
		}

		if(!specialPaste)
			pasteSpan(view, extra, row, col, cutted, translate);
	}

	if(setValue)
		view.setCellValue(row, col, newValue);
}

function _get_sel_style(view, grid){
	var data = [];
	var row, last;

	grid.mapSelection(function(value, id, col) {
		if (id != last){
			row = []; data.push(row);
			last = id;
		}

		var math =  view._mPage.getValue(id-1, col-1, true);
		math = math && math[0] == "=" ? math : null;
		value = view._mPage.getValue(id-1, col-1);
		var obj = { text:value, math, style:view.getStyle(id, col) };

		var condition = view.conditions.get(id,col);
		var editor = view.getCellEditor(id, col);
		var filter = view.getCellFilter(id, col);
		var span = view._table.getSpan(id, col);

		if (editor || filter || condition || span){
			var extra = { row:id, col:col };
			if (condition) extra.condition = condition;
			if (editor) extra.dropdown = editor;
			if (filter) extra.filter = filter;
			if (span) extra.span = span;
			obj.extra = extra;
		}

		row.push(obj);
		return value;
	});

	return data;
}

function allFiltersInZone(extra, origin, columnCount){
	var filters = Object.keys(extra);
	var startRow = Math.min.apply(null, filters);
	var endRow = Math.max.apply(null, filters);
	var startCol = columnCount;
	var endCol = 1;

	for (var i = startRow; i <= endRow; i++){
		if(extra[i]){
			var columns = Object.keys(extra[i]);
			startCol = Math.min(startCol, Math.min.apply(null, columns));
			endCol = Math.max(endCol, Math.max.apply(null, columns));
		}
	}

	if(origin.start.row <= startRow && origin.end.row >= endRow && origin.start.column <= startCol && origin.end.column >= endCol)
		return true;
	else
		return false;
}
//dynamically increase rows and columns according to pasted data size
function _add_row_col(view, start, data){
	var maxRow = start.row + data.length - 1;
	var maxCol = start.column + data[0].length - 1;

	if(maxRow > view.config.rowCount || maxCol > view.config.columnCount){
		var action = { id:"add" };
		if (maxRow > view.config.rowCount) {
			action.group = "row";
			view.callEvent("onCommand", [
				action, 
				{ row: view.config.rowCount + 1 }, 
				{ row: maxRow }
			]);
		}
		if (maxCol > view.config.columnCount) {
			action.group = "column";
			view.callEvent("onCommand", [
				action, 
				{ column: view.config.columnCount + 1 },
				{ column: maxCol }
			]);
		}
	}
}

function _changeDelimiter(data, config){
	const format = getClipboardNumberFormat(config);

	if(format)
		for(let i = 0; i < data.length; i++)
			for(let k = 0; k < data[i].length; k++){
				const record = webix.Number.parse(data[i][k], format);
				if(webix.rules.isNumber(record))
					data[i][k] = record;
			}

	return data;
}

function getClipboardNumberFormat(config){
	let format = config.clipboardNumberFormat;

	if(!format && config.clipboardDecimalDelimiter) // backward compatibility
		format = {
			decimalDelimiter: config.clipboardDecimalDelimiter,
			decimalOptional: true
		};

	return format;
}