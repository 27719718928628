export function init(view) {
	view.attachEvent("onUndo", (action, row, column, value) => {
		if (action == "header-hide"){
			hideHeaders(view, value);
		}
	});
}

export function hideHeaders(view, state){
	const grid = view.$$("cells");
	const old = !grid.config.header;

	if (state == "toggle")
		state = grid.config.header;

	if(state != old){
		grid.config.header = !state;
		view.callEvent("onCommand", [{id:"toolbar-update", name:"hide-headers", value:state}]);
		view.callEvent("onAction", ["header-hide", {newValue:state, value:old}]);
	}

	const firstVisible = grid.isColumnVisible("rowId");
	if((firstVisible && state) || (!firstVisible && !state))
		grid.hideColumn("rowId", {}, true, state);

	grid.refreshColumns();
}

export function serialize(view, data){
	data.table.headers = view.$$("cells").config.header ? 1 : 0;
}

export function load(view, data){
	let mode;
	if(data.table && !webix.isUndefined(data.table.headers))
		mode = !data.table.headers;
	hideHeaders(view, mode);
}