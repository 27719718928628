export function init(view){
	//parsing initial data
	view.attachEvent("onDataParse", (data) => _parse(view, data));
	view.attachEvent("onDataSerialize", (data, config) => _serialize(view, data, config));

	// undo
	view.attachEvent("onUndo", (type, row, column, value) => {
		if(type == "value")
			_undoValue(view, row, column, value);
	});
}

function _serialize(view, obj, config){
	var math = !config || config.math!==false;

	var grid = view.$$("cells");
	var state = grid.getState();
	var columns = state.ids.concat(state.hidden);

	// remove "rowId" column
	columns.splice(columns.indexOf("rowId"), 1);

	obj.data = getSheetData(view, math);

	obj.table = {
		frozenColumns : (view._frozenColumns || 0),
		frozenRows : (view._frozenRows || 0)
	};
}

function _parse(view, obj){
	const tconfig = obj.table || { frozenColumns : 0, frozenRows : 0 };
	view._frozenColumns = view._frozenRows = 0;
	if (tconfig){
		if (!webix.isUndefined(tconfig.frozenColumns))
			view.freezeColumns(tconfig.frozenColumns);
		if (!webix.isUndefined(tconfig.frozenRows))
			view.freezeRows(tconfig.frozenRows);
	}

	if(obj.sizes)
		view.$$("cells").define("fixedRowHeight", false);
}

function _undoValue(view, row, column, value){
	view.setCellValue(row, column, value);
}

export function getSheetData(view, math, page){
	page = page ? view._mData.getPage(page) : view._mPage;
	return page.serialize(math)
		.map(arr =>{
			let value = arr[2].value;
			if(value === null)
				value = "";
			return [ ++arr[0], ++arr[1], value, arr[2].style||"", arr[2].type];
		})
		.filter(arr => {
			return arr[2] || arr[2] === 0 || arr[3];
		});
}