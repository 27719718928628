export const toolbarSizes = {
	width:38,
	margin: 7,
	paddingY: 1,
	sectorPadding: 5,
	sectorMargin: 0
};

// default styles for toolbar elements
export const defaultStyles = {
	"color"         : "#666666",
	"background"    : "#ffffff",
	"font-family"   : "'PT Sans', Tahoma",
	"font-size" : "15",
	"text-align": "left",
	"vertical-align": "middle",
	"white-space": "nowrap",
};

const fonts = {
	material: "'Roboto', sans-serif",
	mini: "'Roboto', sans-serif",
	flat: "'PT Sans', Tahoma",
	compact: "'PT Sans', Tahoma",
	contrast: "'PT Sans', Tahoma",
	willow: "'Open Sans', sans-serif",
	dark: "'Open Sans', sans-serif",
};

export function setSkinDefaults(){
	const skin = webix.skin.$name;
	toolbarSizes.width = webix.skin.$active.inputHeight + 2;
	toolbarSizes.titleHeight = (skin == "mini" || skin == "compact") ? 18 : 20;

	defaultStyles["font-family"] = fonts[skin];

	if (skin == "contrast"){
		defaultStyles.background = "#393939";
		defaultStyles.color = "#ffffff";
	} else if (skin == "material" || skin == "mini"){
		defaultStyles.color = "#475466";
	} else if (skin == "willow"){
		defaultStyles.color = "#2C2F3C";
	} else if (skin == "dark"){
		defaultStyles.background = "#2A2B2D";
		defaultStyles.color = "#ffffff";
	}
}