import {Dialog} from "./common";
import {isVisible} from "../sheets";
import {showBorders} from "../operations/print";

export const action = "print";

export class DialogBox extends Dialog{
	$show(box, form){
		const list = form.elements.sheets.getList();
		list.clearAll();
		list.parse( this.getSheets(this.view) );

		form.setValues({
			data:"full",
			sheets: this.view.getActiveSheet(),
		}, true);
	}
	$init(){
		const labels = webix.i18n.spreadsheet.labels;
		return {
			view: "ssheet-dialog",
			head: labels["print-title"],
			move: true,
			modal:true,
			width:580,
			position: "center",
			buttons:false,
			on:{
				onHide: ()=> {
					webix.extend(this.view._printBorders, this.getValues(), true);
					showBorders(this.view, true);
				}
			},
			body:{
				view:"form",
				elementsConfig:{
					labelWidth: 0
				},
				elements:[
					{ type:"section", template: labels["print-settings"]},
					{ cols:[
						{ view:"radio", name:"data", vertical:true, options:[
							{ id:"full", value: labels["sheets"]},
							{ id:"selection", value: labels["selection"]}
						], on:{
							onChange:function(newv){
								const elements = this.getFormView().elements;
								const sheets = elements.sheets;

								if(newv == "full"){
									elements.sheetnames.setValue(1);
									sheets.enable();
								}
								else
									sheets.disable();
							}
						}},
						{
							padding:{
								right:30
							},
							rows:[
								{
									name: "sheets",
									view: "multiselect",
									width: 160,
									stringResult:false,
									suggest: {
										selectAll: true,
										body:{
											data:[]
										}
									},
								},
								{}
							]
						},
						{ rows:[
							{view:"checkbox", name:"sheetnames", value:1, labelRight:labels["sheet-names"]},
							{view:"checkbox", name:"borderless", labelRight:labels["borderless"]},
							{view:"checkbox", name:"skiprows", labelRight:labels["skip-rows"]},
							{view:"checkbox", name:"margin", labelRight:labels["margin"]},
							{view:"checkbox", name:"external", labelRight:labels["external-ui"]},
						]}
					]},
					{ type:"section", template: labels["print-paper"]},
					{ view:"radio", name:"paper", value:"A4", options:[
						{ id:"letter", value:labels["page-letter"]},
						{ id:"A4", value:labels["page-a4"]},
						{ id:"A3", value:labels["page-a3"]}
					]},
					{ type:"section", template: labels["print-layout"]},
					{ rows:[
						{view:"radio", name:"fit", value:"page", options:[
							{ id:"page", value:labels["page-width"]},
							{ id:"data", value:labels["page-actual"]}
						]},
						{ view:"radio", name:"mode", value:"landscape", options:[
							{ id:"portrait", value:labels["page-portrait"]},
							{ id:"landscape", value:labels["page-landscape"]}
						]}
					]},
					{
						cols:[
							{ },
							{ view: "button", css:"ssheet_cancel_button", hotkey:"esc",
								value: labels.cancel, autowidth:true,
								click: () => this.close()
							},
							{ view: "button", value: labels["print-borders"], autowidth:true,
								click: () => this.printPreview()
							},
							{ view: "button", value: labels.print, hotkey:"enter", autowidth:true,
								click: () => this.okClick()
							}
						]
					}
				]
			}
		};
	}
	getValues(){
		const data = this.$dialog.getBody().getValues({disabled:false});
		data.margin = data.margin?0:{};
		return data;
	}
	printPreview(){
		this.close();
		this.view.showPrintBorders(true);
	}
	getSheets(view){
		return view._sheets
			.map(sheet => ({value: sheet.name, id: sheet.name}))
			.filter(sheet => isVisible(view, sheet.id));
	}
	okClick(){
		const data = this.$dialog.getBody().getValues({disabled:false});
		data.margin = data.margin?0:{};
		this.close();
		webix.print(this.view, data);
	}
}
