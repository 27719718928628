webix.protoUI({
	name: "ssheet-toolbar-scrollview",
	$getSize: function(){
		const size = webix.ui.scrollview.prototype.$getSize.apply(this, arguments);
		const bodySize = this.getBody().$getSize(0, 0);

		let scroll = 0;
		if(this.config.scroll)
			scroll = webix.env.scrollSize;
		else
			this.$view.style["overflow-x"] = "hidden";

		size[2] = size[3] = bodySize[2] + scroll;

		return size;
	},
	$setSize: function(x,y){
		const bodySize = this.getBody().$getSize(0, 0);
		const oldScroll = this.config.scroll;
		const scroll = x < bodySize[0] ? "x" : false;

		if(scroll != oldScroll){
			this.define({scroll});
			return webix.delay(()=>{
				this.resize();
			});
		}

		webix.ui.scrollview.prototype.$setSize.apply(this, [x, y]);
	}
},webix.ui.scrollview);