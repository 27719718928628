import group from "./undo_group";
import {eachRange} from "../helpers/column_names";
import {setSpanCss} from "./spans";
import {conditions} from "../helpers/conditional";
import {getType} from "./types";
import * as arr from "../helpers/array";

export function init(view){
	view.conditions = {
		_empty:true,
		_pull: {},
		handlers: createRules(),
		add: function(rId, cId, cond, val, style){
			add.apply(this, [view, rId, cId, cond, val, style]);
		},
		update: function(r, c, data){
			update.apply(this, [view, r, c, data]);
		},
		remove,
		get,
		parse,
		serialize,
		clear
	};

	view.attachEvent("onConditionSet", (data) => _setCondition(view, data));

	view.attachEvent("onUndo", (type_action, row, column, value) => {
		if(type_action == "condition")
			_undoCondition(view, row, column, value);
	});

	view.attachEvent("onDataSerialize", (data) => _serialize(view, data));
	view.attachEvent("onDataParse", (data) => _parse(view, data));

	view.attachEvent("onReset", () => view.conditions.clear() );

	view.attachEvent("onAction", (action, p)=> {
		if(action == "before-grid-change")
			updatePosition(view, p.name, p.inc, p.data, p.start);
	});

	reset(view);
}

function createRules(){
	const rules = webix.copy(conditions);
	rules.number = rules.number.concat([
		{ id:"between", handler: function(a, b){
			return (!b.start || a > b.start) && (!b.end || a < b.end);
		}},
		{ id:"notBetween", handler: function(a, b){
			return (!b.start || a <= b.start) || (!b.end || a >= b.end);
		}}
	]);
	return rules;
}

function getCellValue(view, value){
	if(isNaN(value*1)){
		if (value.charAt(0) == "=" && value.length > 1){
			value = view._mData.getStore().exec(value.substring(1), view._mPage.getContext());
			if(value === null)
				value = "";
		}
	}
	else {
		value = value*1;
	}
	return value;
}

function reset(view){
	view.conditions.clear();
}

function _setCondition(view, data){
	group.set(function(){
		view.eachSelectedCell((cell) => {
			const collection = view.conditions.get(cell.row, cell.column);
			if(collection)
				for (let i = 0; i < collection.length; i++)
					setSpanCss(view, cell.row, cell.column, collection[i][2], false);
			_changeCondition("update", cell.row, cell.column, (collection||null), data, view);
		});
	});
	view.refresh();
}

function _undoCondition(view, row, column, value){
	if(view.conditions.get(row, column))
		view.conditions.remove(row, column);
	if(value)
		view.conditions.update(row, column, value);
}

function _serialize(view, obj){
	obj.conditions = view.conditions.serialize();
}

function _parse(view, obj){
	view.conditions.parse(obj.conditions);
}

export function getConditionCss(view, row, column, rendered){
	let css = "";

	if (!view.conditions._empty){
		const collection = view.conditions.get(row, column);
		if(collection){
			const span = view.$$("cells").getSpan(row, column);
			let value = view.getCellValue(row,column,false);
			if(!value && value !== 0)
				value = "";

			let type = getType(view, row, column);
			if(!type || type == "string")
				type = "text";

			for (let i = collection.length - 1; i >= 0; i--){
				let currentValue = value;
				const rule = collection[i][0];
				const handlers = view.conditions.handlers;
				let handler;

				let checkOrder = ["text", type, "number", "date"];
				checkOrder = checkOrder.filter((v, i) => checkOrder.indexOf(v) == i);

				for(let k = 0; k < checkOrder.length; k++){
					const checkType = checkOrder[k];

					handler = arr.find(handlers[checkType], obj => obj.id == rule);
					if(handler){
						handler = handler.handler;
						if(checkType == "text" && rule != "equal" && rule != "notEqual")
							currentValue = rendered;
						break;
					}
				}

				let compare = collection[i][1];
				if(webix.isArray(compare))
					compare = {
						start: getCellValue(view, compare[0]),
						end: getCellValue(view, compare[1])
					};
				else
					compare = getCellValue(view, compare);

				if(!css && handler && handler(currentValue, compare)){
					css = collection[i][2];
					if(!span)
						break;
					setSpanCss(view, row, column, collection[i][2], true);
				}
				else
					setSpanCss(view, row, column, collection[i][2], false);
			}
		}
	}
	return css;
}


function addEmptyCollection(pull, row, column, state){
	state._empty = false;
	if(!pull[row])
		pull[row] = {};
	if(!pull[row][column])
		pull[row][column] = [];
	return pull[row][column];
}


function parse(data){
	//backward compatibility
	const oldConditions = {
		">": "greater",
		"<": "less",
		"=": "equal",
		"!=": "notEqual",
		"<>": "between"
	};

	this._empty = true;
	if (!data) return;

	let i = data.length;
	while(i--){
		const c = data[i];
		const collection = addEmptyCollection(this._pull,c[0],c[1],this);
		c[2] = oldConditions[ c[2] ] || c[2];
		collection.push([c[2],c[3],c[4]]);
	}
}

function clear(){
	this._pull = {};
}

function update(view, row, column, newData){
	var collection = this.get(row, column);
	if(!collection)
		addEmptyCollection(this._pull, row, column, this);

	newData.map(item => _normalizeValue(view, item) );
	this._pull[row][column] = newData;
}

function get(rowId, columnId){
	if(!rowId)
		return this._pull;
	if(!columnId)
		return this._pull[rowId];
	return this._pull[rowId]?this._pull[rowId][columnId]:null;
}

function add(view, rowId, columnId, condition, value, style){
	const collection = addEmptyCollection(this._pull, rowId, columnId, this);
	collection.push( _normalizeValue(view, [condition, value, style]) );
}

function remove(rowId, columnId){
	var collection = this.get(rowId, columnId);
	if(collection)
		delete this._pull[rowId][columnId];
}

function serialize(){
	var column, condition, i, row,
		data = [];

	for( row in this._pull)
		for( column in this._pull[row])
			for(i=0; i < this._pull[row][column].length; i++){
				condition = this._pull[row][column][i];
				data.push([row,column, condition[0],condition[1],condition[2]]);
			}

	return data;
}

function updatePosition(view, name, inc, data, start){
	let conditions = data.conditions,
		i = conditions.length;

	if(inc){
		while(i--){
			let [row,column] = conditions[i];
			if(name == "row" && row>= start.row || name == "column" && column >= start.column){
				if (name == "row"){
					if (row < start.row - inc)	//delete lock mark if row was deleted
						conditions.splice(i,1);
					else						//update mark position if upper row was deleted
						conditions[i][0] = row*1 + inc;
				} else if (name == "column"){
					if (column < start.column - inc){
						conditions.splice(i, 1);
					} else 
						conditions[i][1] = column*1 + inc;
				}
			}
		}
	}
}

export function clearConditionalFormats(view, range){
	group.set(function(){
		eachRange(range, view, function(view, cell){
			var condition = view.conditions.get(cell.row, cell.column);
			if(condition)
				_changeCondition("remove", cell.row, cell.column, condition, null, view);				
		});
		view.refresh();
	});
}

export function pasteCondition(view, extra, row, col, cutted){
	var condition = extra.condition;
	var collection = view.conditions.get(row,col);
	_changeCondition("update", row, col, (collection||null), condition, view);
	if(cutted === 1)
		_changeCondition("remove", extra.row, extra.col, condition, null, view);
}

export function _changeCondition(name, row, column, oldCondition, newCondition, view){
	if (view.callEvent("onBeforeConditionSet", [row, column, oldCondition, newCondition])){
		view.conditions[name].apply(view.conditions, [row, column, newCondition]);
		view.callEvent("onAfterConditionSet", [row, column, oldCondition, newCondition]);
	}
}

function _normalizeValue(view, condition){
	const val = condition[1];
	if(val[0] == "=")
		condition[1] = view._mData.getStore().parse(val).source; //fixes formula
	return condition;
}