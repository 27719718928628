// toolbar: toogle button
webix.protoUI({
	$cssName: "toggle",
	name:"ssheet-toggle",
	toggle:function(){
		const old = this.getValue();

		if(!old || old == this.config.offValue)
			this.config.value = this.config.onValue || true;
		else
			this.config.value = this.config.offValue || false;

		const val = this.getValue();
		this.$setValue(val);
		this.callEvent("onChange", [val, old, "user"]);
	},
	$setValue:function(value){
		if(value == this.config.offValue)
			value = false;
		else if(value == this.config.onValue)
			value = true;
		webix.ui.toggle.prototype.$setValue.call(this,value);
	},
	getValue:function(){
		var config = this.config;
		var value = config.value;
		return  !value || value==config.offValue?(config.offValue||false):(config.onValue||true);
	},
	defaults:{
		template:function(obj, common){
			var css = (obj.value === true || obj.value == obj.onValue) ? " webix_pressed" : "";
			var inp = common.$renderInput(obj, common);
			return "<div class='webix_el_box"+css+"' style='width:"+obj.awidth+"px; height:"+obj.aheight+"px'>"+inp+"</div>";
		}
	}
}, webix.ui.toggle);


// toolbar: silent toggle button
webix.protoUI({
	name:"ssheet-toggle-silent",
	$cssName:"toggle",
	$allowsClear:false,
	setValue:function(){
		this.blockEvent();
		webix.ui.toggle.prototype.setValue.apply(this, arguments);
		this.unblockEvent();
	}
}, webix.ui.toggle);