import "./suggest_formula";
import {getJsDateFromExcel} from "../mathematics/methods";
import {getType} from "../operations/types";
import {getDateEditFormat} from "../operations/formats";
import {split, position, getLastCharIndex} from "../mathematics/parser";

webix.protoUI({
	name: "live-editor",
	$cssName:"texthighlight",
	$init(config){
		config.suggest = {
			view: "suggest-formula",
			data: config.suggestData
		};
		config.highlight = this.highlight;

		this._highlightedCells = {};
		this.$view.className += " webix_ssheet_formula";

		this.attachEvent("onKeyPress", (code, e) => {
			delete this._update_range;
			if(code == 13) {
				//ignore Enter key if it was pressed to select some value from suggest
				if ((new Date() - (this._last_value_set || 0)) > 300){
					const cell = this.config.activeCell;
					this.showActiveSheet(true);
					this.updateCellValue(cell);
					//blur to avoid calling highlight function after selection move
					this.getInputNode().blur();
					//we have 'enter' hot key to activate editor
				}
				webix.html.preventEvent(e);
			}
			//esc - cancel editing
			else if(code == 27){
				this.showActiveSheet();

				webix.delay(()=>{
					const master = this.getTopParentView();
					const cell = master.getSelectedId();
					const oldVal = this.getCellValue(cell.row, cell.column);

					if(this.getValue() == oldVal)
						master.$$("cells").refresh(cell.row);
					else
						this.setValue(oldVal);

					delete master.$handleSelection;

					webix.UIManager.setFocus(master.$$("cells"));
				});
			}
		});

		this.attachEvent("onAfterRender", function(){
			webix.eventRemove(this.keydownEv);

			this.keydownEv = webix.event(this.getInputNode(), "keydown", e => {
				this.endEdit(e);
			});
		});
	},
	highlight(value){
		let html = "";
		const master = this.getTopParentView();
		const escapeText = webix.template.escape;

		for(let area in this._highlightedCells){
			const cells = this._highlightedCells[area];
			this.changeCellCss(cells[0], cells[1], cells[2], true);
		}
		this._highlightedCells = {};

		//value can be null
		if(value && value[0]=="="){
			const parts = split(value, master, null, this._activeMath);
			const sheet = master.getActiveSheet();

			let colorIndex = 1;

			for(let i = 0; i < parts.length; i++){
				if(i%2 === 0)
					html += escapeText(parts[i]);
				else{
					const crossheet = webix.isArray(parts[i]);
					const cellSheet = crossheet ? this.prepareSheet(parts[i][0], parts[i][2]) : "";
					const activeMath = this._activeMath;

					const activeSheet = !crossheet && (!activeMath || activeMath == sheet);
					let cell = position(crossheet ? parts[i][1] : parts[i]);
					const cellText = crossheet ? cellSheet+parts[i][1] : parts[i];

					const rangeName = crossheet && !getLastCharIndex(parts[i][1]);

					if(rangeName || activeSheet || (crossheet && parts[i][0] == sheet)){
						let repeatedColor;
						//range
						if((parts[i+1] == ":" && parts[i+2]) || rangeName){
							let nextCell, nextCellText;

							if(rangeName){
								const cells = parts[i][3].split(":");

								cell = position(cells[0]);
								nextCell = position(cells[1]);
							}
							else{
								nextCell = position(webix.isArray(parts[i+2]) ? parts[i+2][1] : parts[i+2]);
								nextCellText = webix.isArray(parts[i+2]) ? cellSheet+parts[i+2][1] : parts[i+2];
								i+=2;
							}

							repeatedColor = this.setColor(cell[0], cell[1], nextCell[0], nextCell[1], colorIndex);
							html += `<span class="webix_ssheet_highlight_color_${repeatedColor||colorIndex}">${rangeName ? cellText : cellText+":"+nextCellText}</span>`;
						}
						//OtherSheet!A1:A3 - do not highlight A3
						else if(parts[i-1] && parts[i-1][ parts[i-1].length-1 ] == ":"){
							html += cellText;
							continue;
						}
						//cell
						else{
							repeatedColor = this.setColor(cell[0], cell[1], cell[0], cell[1], colorIndex);
							html += `<span class="webix_ssheet_highlight_color_${repeatedColor||colorIndex}">${cellText}</span>`;
						}

						const colors = 7;
						if(!repeatedColor)
							colorIndex += colorIndex == colors ? -1*(colors-1) : 1;
					}
					else
						html += cellText;
				}
			}
		}
		else
			html = escapeText(value);

		master.$$("cells").refresh();

		if(document.activeElement == this.getInputNode())
			this.paintValue();
		return html;
	},
	showActiveSheet(enter){
		const master = this.getTopParentView();
		const grid = master._table;

		if(this._activeMath){
			if(this._activeMath != master.getActiveSheet()){
				const cell = this.config.activeCell;

				this.define({activeCell:null});

				master.showSheet(this._activeMath);

				webix.delay(()=>{
					grid.select(cell.row + (enter ? 1 : 0), cell.column);
				});
			}
			delete this._activeMath;
		}
		else if(enter){
			webix.delay(()=>{
				grid.moveSelection("down");
			});
		}
	},
	changeCellCss(row, col, index, remove){
		const css = `webix_ssheet_highlight_background_${index}`;
		for (let r = row.start; r <= row.end; r++)
			for (let c = col.start; c <=  col.end; c++)
				this.getTopParentView().$$("cells")[remove?"removeCellCss":"addCellCss"](r, c, css, true);
	},
	endEdit(e){
		const code = e.which || e.keyCode;

		//if it is a formula - force user to finish it by click outside or 'enter'
		if(code == 9){
			webix.html.preventEvent(e);

			this.getInputNode().blur();
			this.updateCellValue();
			this.getTopParentView()._table.moveSelection(e.shiftKey ? "left" : "right");
		}
	},
	paintValue(){
		const master = this.getTopParentView();
		if(!this._activeMath || this._activeMath == master.getActiveSheet()){
			const cell = this.config.activeCell;
			const node = master._table.getItemNode(cell);
			if(cell && node) 
				node.innerHTML = `<div>${webix.template.escape(this.getValue())}</div>`;
		}
	},
	updateCellValue(cell) {
		const newv = this.getValue();
		const master = this.getTopParentView();
		cell = cell || this.config.activeCell;

		if (!cell)
			this.setValue("");
		else{
			const val = master.getCellValue(cell.row, cell.column);

			if(newv !== val){
				this.config.value = newv;
				master.setCellValue(cell.row, cell.column, newv);
				master.refresh();
			}
		}

		delete master.$handleSelection;
	},
	$setValueHere(value){
		this.setValueHere(value);
	},
	setValueHere(value) {
		this._last_value_set = new Date();

		const formula = this.getValue();
		if(formula && formula.charAt(0)==="="){
			const cursor = this.getInputNode().selectionStart;
			let str1 = formula.substring(0, cursor);
			const str2 = formula.substring(cursor);

			if(str1[str1.length-1] == "(")
				str1 = str1.substring(0, str1.length-1);

			str1 = str1.substring(0, getLastCharIndex(str1, true));

			//suggest called via up/down key
			str1 += value + (str2[0] == "(" ? "" : "(");

			this.setValue(str1 + str2);
			this.getInputNode().setSelectionRange(str1.length, str1.length);
		}
	},
	expectOperator(){
		const text = this.getValue();

		if(text[0] == "="){
			let cursor = this.getInputNode().selectionStart;
			while(text[cursor-1] == " ")
				cursor--;
			return text[cursor-1] && text[cursor-1].match(/[+&\-/*=(:,]/);
		}
	},
	setRange(range, replace){
		const cursor = this.getInputNode().selectionStart;
		const formula = this.getValue();

		let str1, str2;

		//check if range was added by click
		if(replace)
			replace = this._update_range && cursor == this._update_range.pos + this._update_range.len;

		const lastPos = replace ? this._update_range.pos : cursor;

		str1 = formula.substring(0, lastPos) + range;
		str2 = formula.substring(cursor);

		this._update_range = {pos: lastPos, len: range.length};

		this.setValue(str1 + str2);
	},
	prepareSheet(sheet, escaped){
		if(!sheet)
			return "";
		const condition = webix.isUndefined(escaped) ? sheet.indexOf(" ") != -1 : escaped;
		return (condition ? `'${sheet}'` : sheet) + "!";
	},
	prepareArea(first, second){
		return {
			start: Math.min(first, second),
			end: Math.max(first, second)
		};
	},
	setColor(row1, col1, row2, col2, color){
		const row = this.prepareArea(row1, row2);
		const col = this.prepareArea(col1, col2);
		const index = [row.start, row.end, col.start, col.end].join(",");
		const coloredArea = this._highlightedCells[index];

		if(!coloredArea){
			this._highlightedCells[index] = [row, col, color];
			this.changeCellCss(row, col, color);
		}
		else
			return coloredArea[2];
	},
	getCellValue(row, column){
		const master = this.getTopParentView();
		let value = master.getCellValue(row, column);
		if(getType(master, row, column) == "date" && value[0] != "="){
			const format = getDateEditFormat(master, row, column);
			value = format(getJsDateFromExcel(value));
		}
		return value;
	}
}, webix.ui.texthighlight);