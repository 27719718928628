import {showBorders} from "../operations/print";

export function showPrintBorders(value){
	const old = !!this._printBorders.$render;

	if(value == "toggle")
		value = !old;

	if(value != old){
		if(value)
			showBorders(this);
		else{
			delete this._printBorders.$render;
			this.refresh();
		}

		this.callEvent("onCommand", [{id:"toolbar-update", name:"print-borders", value}]);
		this.callEvent("onAction", ["print-borders", {newValue: value, value: old}]);
	}
}