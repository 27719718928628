import {isCellSelected, isColSelected,isRowSelected,selectRow,selectColumn} from "./operations/column_operations";
import {getColumnOperation, getRowOperation, getClearOperation} from "./toolbar/buttons";

export function init(view){
	view.attachEvent("onComponentInit", () => view.$$("context").attachTo(view._table.$view));

	const locale = webix.i18n.spreadsheet.menus;

	let clipboard = [];
	if(view.config.clipboard !== false)
		clipboard = [
			{ id:"cut", value: locale.cut},
			{ id:"copy", value: locale.copy},
			{ id:"paste", value: locale.paste},
			{ id:"special-paste", value: locale["special-paste"], submenu:[
				{id: "paste-values", value: locale["paste-values"]},
				{id: "paste-formulas", value: locale["paste-formulas"]},
				{id: "paste-styles", value: locale["paste-styles"]},
				{id: "paste-conditions", value: locale["paste-conditions"]},
			]}
		];

	const lists = {
		data: clipboard.concat([
			{ id:"clear",		value: locale["clear"], submenu: getClearOperation()},
			{ id:"lock-cell",		value: locale["lock-cell"]},
			{ $template: "Separator"},
			{ id:"sort",	value: locale.sort, submenu:[
				{id: "sort-asc", value: locale["sort-asc"]},
				{id: "sort-desc", value: locale["sort-desc"]}
			]},
			{id:"create-filter",		value: locale["create-filter"]},
			{ $template: "Separator"},
			{ id:"add-range",		value: locale["add-range"]},
			{ id:"add-link",		value: locale["add-link"]},
			{ id:"add-comment",		value: locale["add-comment"]}
		]),
		column: getColumnOperation(view),
		row: getRowOperation(view)
	};


	var context = {
		view: "contextmenu",
		id: "context",
		padding:0,
		submenuConfig:{
			padding:0,
			on:{
				onBeforeShow:function(){
					this.sizeToContent();
				}
			}
		},
		data:[],
		on:{
			onMenuItemClick(id) {
				view.callEvent("onCommand", [this.getMenuItem(id)]);
			},
			onBeforeShow(pos) {
				webix.callEvent("onClick", []);
				var mode, trg;

				trg = view._table.locate(pos);
				if (!trg) return false;

				mode = "";
				if (trg.header && trg.column !== "rowId"){
					if (!isColSelected(trg.column, view))
						selectColumn(trg.column, view);
					mode = "column";
				} else if (trg.row){
					if (trg.column === "rowId"){
						if (!isRowSelected(trg.row, view))
							selectRow(trg.row, view);
						mode = "row";
					} else {
						if (!isCellSelected(trg.row, trg.column, view))
							view._table.addSelectArea(trg, trg);
						mode = "data";
					}
				}

				if (mode){
					var data = getMenuData(view, mode, lists[mode]);
					if (data){
						this.clearAll();
						this.parse(data);
						this.sizeToContent();
						webix.html.preventEvent(pos);
						return true;
					}
				}
				return false;
			}
		}
	};

	view.callEvent("onViewInit", ["context", context]);
	const contextMenu = view.ui(context);

	view._destroy_with_me.push(contextMenu);
}

export function getMenuData(view, name, data){
	var ev = { area:name, data:data };
	if (view.callEvent("onContextMenuConfig", [ev]))
		return ev && ev.data;
	return null;
}