import {save} from "../operations/save";

export function saveCell(row, column, page){
	var style = this.getStyle(row, column);

	save(this, "data", {
		row,
		column,
		value: this.getCellValue(row, column, null, page),
		page: page || this.getActiveSheet(),
		style: (style ? style.id : "")
	});
}