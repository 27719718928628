export function init(view) {
	view.attachEvent("onUndo", (action, row, column, value) => {
		if (action == "show-formulas"){
			showFormulas(view, value);
		}
	});
}

export function showFormulas(view, value){
	const grid = view.$$("cells");
	const old = grid.config.showFormulas;

	if(value == "toggle")
		value = !old;

	if(value != old){
		grid.config.showFormulas = value;

		view.callEvent("onCommand", [{id:"toolbar-update", name:"show-formulas", value}]);
		view.callEvent("onAction", ["show-formulas", {newValue: value, value: old}]);

		grid.refresh();
	}
}

export function serialize(view, data){
	data.table.showFormulas = view.$$("cells").config.showFormulas ? 1 : 0;
}

export function load(view, data){
	let mode = false;
	if(data.table && data.table.showFormulas)
		mode = data.table.showFormulas;
	showFormulas(view, mode);
}