import {toRange,rangeObj,range as getRange} from "../helpers/column_names";
import * as tbl from "../table";
import group from "../operations/undo_group";
import {newSheet, prepareCell} from "../sheets";
import {getType, loadCell} from "../operations/types";
import {getExcelDateFromJs} from "../mathematics/methods";
import {checkFormat, getFormatFromData} from "../operations/formats";
import * as arr from "../helpers/array";

export function refresh(all){
	if (all)
		this._table.refreshColumns();
	else
		this._table.refresh();
}
export function eachSelectedCell(cb){
	var cells = this.getSelectedId(true);
	for (var i=0; i<cells.length; i++)
		cb.call(this, cells[i]);
}
export function getSelectedRange(sheet){
	var area = this._table.getSelectArea();
	if (area){
		if (sheet)
			sheet = this.getActiveSheet();
		return toRange(area.start.row,area.start.column,area.end.row,area.end.column,sheet);
	}
	return "";
}
export function getSelectedId(all){
	var area = this._table.getSelectArea();
	if(!all)
		return area && area.start.row ? area.start : null;

	var selection = [];
	if(area){
		var c0 = area.start;
		var c1 = area.end;

		for(var i = c0.row; i<= c1.row; i++){
			for(var j = c0.column; j <= c1.column; j++){
				selection.push({row: i,column:j});
			}
		}
	}

	return selection;
	//return this._table.getSelectedId(all)
}
export function getCellValue(row, column, math, page){
	page = page ? this._mData.getPage(page) : this._mPage;

	if(!page)
		return;

	const value = page.getValue(row-1, column-1, math !== false);

	return correctVal(value);
}
export function setCellValue(row, column, value, page){
	if(!page)
		page = this.getActiveSheet();

	const muonPage = this._mData.getPage(page);
	if(!muonPage)
		return;

	value = correctVal(value);
	const old = this.getCellValue(row, column, page);
	const oldType = getType(this, row, column, page);

	let sameValue;
	if(oldType == "date"){
		const parser = webix.Date.strToDate( webix.i18n.spreadsheet.formats.parseDateTime );
		const dateValue = isNaN(value) ? getExcelDateFromJs(parser(value)) : value;
		sameValue = old == dateValue;
	}
	else if(oldType != "string" && value !== "" && !isNaN(value) && old == value){
		sameValue = true;
		value = value*1;
	}

	if (!sameValue && old !== value){
		group.set(()=>{
			if (this.callEvent("onBeforeValueChange",[row, column, value, old, page])){
				if(page != this.getActiveSheet()){
					const sheet = arr.find(this._sheets, sheet => sheet.name == page).content;
					let cell = arr.find(sheet.data, cell => cell[0] == row && cell[1] == column);

					if(!cell){
						cell = [row, column];
						sheet.data.push(cell);
					}
					cell[2] = value;

					this._page_value_set = true;
					const item = loadCell(this, sheet, cell);
					delete this._page_value_set;

					const muonItem = muonPage.getCell(row-1, column-1, true);
					muonItem.$format = checkFormat({ fmt: getFormatFromData(sheet, cell) }, "string") ? 1 : 0;
					muonPage.setValue(row-1, column-1, item.value);

					cell[2] = item.value;
					cell[4] = muonItem.type = item.type;

					//prepareCell can change style (1/1/2001 --loadCell--> 36892 + date type --prepareCell--> date format)
					prepareCell(cell, sheet, true);
					muonItem.style = cell[3];

					this.callEvent("onCellChange", [row, column, item.value, page]);
				}
				else {
					this._mPage.setValue(row-1, column-1, value);

					//onCellChange can change item (via types module)
					this.callEvent("onCellChange", [row, column, value, page]);
					const updatedType = getType(this, row, column);

					//recalculate if we refer to a cell, where type is string and value is something like 005 or 5e5
					if(oldType != updatedType && updatedType == "string" && value[0] == "=" && value.length > 1)
						this._mPage.setValue(row-1, column-1, value);

					if(/^=hyperlink\(/i.test(value)){
						const baseStyle = this.getStyle(row, column);
						const urlStyle = this.addStyle({ color: "#0000ff", underline: "underline" }, baseStyle);
						this.setStyle(row, column, urlStyle);
					}
				}
				this.saveCell(row, column, page);
			}
		});
	}
}

function correctVal(val){
	if(typeof val === "boolean")
		val *= 1;
	return val || val === 0 ? val : "";
}

export function setRangeValue(range, value, page){
	group.set(function(){
		const pos = rangeObj(range, this);
		let i = 0;
		for (let row = pos.start.row; row <= pos.end.row; row++) {
			for (let column = pos.start.column; column <=  pos.end.column; column++, i++) {
				this.setCellValue(row, column, webix.isArray(value) ? value[i] : value, page);
			}
		}
	},this);
}

export function getRangeValue(range, page){
	if(!page)
		page = this.getActiveSheet();

	const pos = getRange(range, this, page);
	const muonPage = this._mData.getPage(pos[4]);

	if (!pos || !muonPage) return [];

	return muonPage.getRange(pos[0]-1, pos[1]-1, pos[2]-1, pos[3]-1).map(correctVal);
}

export function getRow(id){
	const row = webix.copy(this._table.getItem(id));

	for(let i = 1; i <= this.config.columnCount; i++){
		const cell = this._mPage.getCell(id-1, i-1);

		if(cell){
			row[i] = cell.value;

			[["style", "$cellCss"], ["type", "$cellType"], ["format", "$cellFormat"]].forEach(param => {
				const val = cell[param[0]];
				if(val){
					if(!row[param[1]])
						row[param[1]] = {};
					row[param[1]][i] = val;
				}
			});

			const math =  this.getCellValue(id, i, true);
			if(math && math[0] == "=")
				row["$"+i] = math;
		}
	}

	return row;
}
export function getColumn(id){
	return this._table.getColumnConfig(id);
}
export function reset(mode){
	var obj = { data:[] };
	if (mode && mode.sheets){
		obj = newSheet(obj);
	}
	
	this.parse(obj);
}

export function _resetTable(){
	tbl.reset(this);
}
