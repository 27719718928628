import {toolbarSizes, defaultStyles} from "./defaults";
import {formatHelpers} from "../operations/formats";

// options for 'font-family' select
export const fontFamily = [
	{id: "Arial", value: "Arial"},
	{id: "'Roboto', sans-serif", value: "Roboto"},
	{id: "'PT Sans', Tahoma", value: "PT Sans"},
	{id: "'Open Sans', sans-serif", value:"Open Sans"},
	{id: "Tahoma", value: "Tahoma"},
	{id: "Verdana", value: "Verdana"},
	{id: "Calibri, Tahoma", value:"Calibri"}
];

// options for 'format' select
function getCellFormats(){
	const locale = webix.i18n.spreadsheet.labels;
	return [
		{id: "common", value: locale.common },
		{id: "price", value: locale.currency, example: "98.20"},
		{id: "int", value: locale.number, example: "2120.02"},
		{id: "percent", value: locale.percent, example:"0.5"},
		{id: "date", value: locale.date, example:"45000"},
		{id: "string", value: locale.string, example:"012345"},
		{id: "custom", value: locale["custom-format"]},
	];
}

export function getColumnOperation(view){
	const locale = webix.i18n.spreadsheet.menus;
	let colOperations = [
		{ id:"add",    group:"column", value:locale["insert-column"]},
		{ id:"del",    group:"column", value:locale["delete-column"]},
		{ id:"show",   group:"column", value:locale["show-column"], neighbors:true},
		{ id:"hide",   group:"column", value:locale["hide-column"]}
	];
	if(view.config.resizeCell != false)
		colOperations.push({ id:"resize", group:"column", value:locale["resize-column"]});
	return colOperations;
}

export function getRowOperation(view){
	const locale = webix.i18n.spreadsheet.menus;
	let rowOperations = [
		{ id:"add",    group:"row", value:locale["insert-row"]},
		{ id:"del",    group:"row", value:locale["delete-row"]},
		{ id:"show",   group:"row", value:locale["show-row"], neighbors:true},
		{ id:"hide",   group:"row", value:locale["hide-row"]}
	];
	if(view.config.resizeCell != false)
		rowOperations.push({ id:"resize", group:"row", value:locale["resize-row"]});
	return rowOperations;
}

// options for 'clear' select
export function getClearOperation(){
	return [
		{id: "clear-value",               value: getLocaleClear("value")},
		{id: "clear-style",               value: getLocaleClear("style")},
		{id: "clear-conditional-formats", value: getLocaleClear("conditional-formats")},
		{id: "clear-dropdown-editors",    value: getLocaleClear("dropdown-editors")},
		{id: "clear-comments",            value: getLocaleClear("comments")},
		{ $template: "Separator" },
		{id: "clear-all",                 value: getLocaleClear("all")}
	];
}

// options for 'borders' select
const borders = [
	{id: "no", value: "no"},
	{id: "left", value: "left"},
	{id: "top", value: "top"},
	{id: "right", value: "right"},
	{id: "bottom", value: "bottom"},
	{id: "all", value: "all"},
	{id: "outer", value: "outer"},
	{id: "top-bottom", value: "top-bottom"}
];

function getLocaleClear(value){
	var locale = webix.i18n.spreadsheet.menus;
	return locale["clear"] + " " + locale["clear-"+value].toLowerCase();
}

// options for 'font-size' select
function getFontSize(){
	return [
		"8","9","10","11","12","14","15",
		"16","18","20","22","24","28","36"
	];
}

export const styleFormats = {
	"font-weight": "bold",
	"font-style": "italic",
	"underline": "underline",
	"strike": "strike"
};

export const ui = {
	button: function(config){
		return {
			view: "ssheet-toggle", width: config.width||toolbarSizes.width, id: config.name, name: config.name,  label: config.label,
			css: config.css||"", onValue: styleFormats[config.name], offValue: "normal",
			tooltip: webix.i18n.spreadsheet.tooltips[config.name]||""
		};
	},
	colorButton: function(config){
		return {
			view: "ssheet-color",  css: config.css,  name: config.name, width: config.width||toolbarSizes.width+24,
			title: "<span class='webix_ssheet_button_icon webix_ssheet_color_button_icon webix_ssheet_icon ssi-"+config.name+"' ></span>",
			tooltip: webix.i18n.spreadsheet.tooltips[config.name]||""
		};
	},
	toggleButton: function(config){
		return {
			view: "ssheet-toggle-silent", width: toolbarSizes.width, id: config.name, name: config.name,
			label: "<span class='webix_ssheet_button_icon webix_ssheet_icon ssi-"+(config.icon||config.name)+"'></span>",
			tooltip: webix.i18n.spreadsheet.tooltips[config.name]||""
		};
	},
	iconButton: function(config){
		var btn = webix.copy(config);
		webix.extend(btn, {view: "button", width: toolbarSizes.width, id: config.name,
			label: "<span class='webix_ssheet_button_icon webix_ssheet_icon ssi-"+config.name+"'></span>",
			css: "",
			tooltip: webix.i18n.spreadsheet.tooltips[config.name]||webix.i18n.spreadsheet.menus[config.name]||""
		});
		if(config.onValue){
			webix.extend(btn,{view: "ssheet-toggle", onValue: config.onValue, offValue: config.offValue},true);
		}

		return btn;
	},
	segmented: function(config){
		return  {
			view: "segmented", name: config.name, css:config.css||"", width: config.width||toolbarSizes.width+76,  options:config.options
		};
	},
	select: function(config){
		webix.extend(config,{
			view: "richselect",
			id: config.name,
			value: defaultStyles[config.name],
			suggest: {
				css: "webix_ssheet_suggest",
				padding: 0,
				data: config.options
			}
		});

		config.tooltip = webix.i18n.spreadsheet.tooltips[config.name]||"";
		if(config.popupWidth){
			config.suggest.fitMaster = false;
			config.suggest.width = config.popupWidth;
		}
		if(config.popupTemplate)
			config.suggest.body = {
				template: config.popupTemplate
			};
		if(config.popupEvents){
			config.suggest.body = config.suggest.body || {};
			config.suggest.body.on = config.popupEvents;
		}
		return config;
	},
	separator: function(){
		return {
			view: "ssheet-separator"
		};
	},
	title: function(config){
		var title = config.title;
		if(title.indexOf("$") === 0)
			title = "";
		title = webix.i18n.spreadsheet.labels[config.title]||title;

		return {
			template: title, view: "ssheet-bar-title", height: toolbarSizes.titleHeight
		};
	},
	borders: function(config){
		return {view: "ssheet-borders", width:config.width||toolbarSizes.width+24, data: borders, id: config.name, name: config.name,
			tooltip: webix.i18n.spreadsheet.tooltips[config.name] };
	},
	align: function(config){
		return {view: "ssheet-align", value: defaultStyles[config.name], width:config.width||toolbarSizes.width+24, data: config.options,
			name: config.name, tooltip: webix.i18n.spreadsheet.tooltips[config.name] };
	},
	condFormat: function(config){
		return {
			view: "ssheet-cond-format",
			width: 40,
			id: config.name,
			name: config.name
		};
	}
};

export const buttonsMap = {
	"font-family": function(){
		return  ui.select({name: "font-family", options:fontFamily, width: 100, view:"combo"});
	},
	"font-size": function(){
		return  ui.select({name: "font-size", options:getFontSize(), width: 70, view:"text", icon: "wxi-menu-down", css: "webix_ssheet_text",
			on: {
				onItemClick: function(){
					const suggest = webix.$$(this.config.suggest);
					if(!suggest.isVisible())
						suggest.show(this.getInputNode());
				}
			}
		});
	},
	"font-weight": function(){
		return ui.button({name:"font-weight",label: "B", css: "webix_ssheet_bold"});
	},
	"font-style": function(){
		return ui.button({name: "font-style",label: "I",css:"webix_ssheet_italic"});
	},
	"underline": function(){
		return ui.button({name: "underline",label: "U",css: "webix_ssheet_underline"});
	},
	"strike": function(){
		return ui.button({name: "strike",label: "S",css: "webix_ssheet_strike"});
	},
	"color": function(){
		return ui.colorButton({name: "color",icon:"font",css:"webix_ssheet_color"});
	},
	"background": function(){
		return  ui.colorButton({name: "background",icon:"paint-brush",css:"webix_ssheet_background", width: 70});
	},
	"borders": function(){
		return ui.borders({ name: "borders" });
	},
	"text-align": function(){
		var locale = webix.i18n.spreadsheet.tooltips;
		return ui.align({name: "text-align", css: "webix_ssheet_align", options:[
			{id: "left", css: "webix_ssheet_icon ssi-left", tooltip: locale["align-left"]},
			{id: "center", css: "webix_ssheet_icon ssi-center", tooltip: locale["align-center"]},
			{id: "right", css: "webix_ssheet_icon ssi-right", tooltip: locale["align-right"]}
		]});
	},
	"vertical-align": function(){
		var locale = webix.i18n.spreadsheet.tooltips;
		return  ui.align({name: "vertical-align", css: "webix_ssheet_align", options:[
			{id: "top", css: "webix_ssheet_icon ssi-top", tooltip: locale["align-top"]},
			{id: "middle", css: "webix_ssheet_icon ssi-middle", tooltip: locale["align-middle"]},
			{id: "bottom", css: "webix_ssheet_icon ssi-bottom", tooltip: locale["align-bottom"]}
		]});
	},
	"wrap": function(){
		return ui.iconButton({name: "wrap", onValue:"wrap", offValue:"nowrap"});
	},
	"format": function(){
		return ui.select({
			name: "format", width: 106, options:getCellFormats(), popupWidth: 180,
			popupTemplate: function(obj){
				//make it look like an actual cell
				var format =  formatHelpers[obj.id] ? formatHelpers[obj.id].getFormat : "";
				var cell = {css:""};
				var example = format && obj.example?format(obj.example, cell):"";
				return obj.value + (format?"<span class='webix_ssheet_right"+(cell.css?" "+cell.css:"")+"'>"+example+"</span>":"");
			},
			popupEvents:{
				onItemClick: function(id){
					if(id == "custom")
						this.getTopParentView().callEvent("onCommand", [{id:id}]);
				}
			}
		});
	},
	"column": function(view){
		return {
			name: "column",
			view: "ssheet-button", icon: "column",
			arrow: true, area: true, width: 58,
			options:getColumnOperation(view)
		};
	},
	"row": function(view){
		return {
			name: "row", view: "ssheet-button", icon: "row",
			arrow: true, area: true, width: 58,
			options:getRowOperation(view)
		};
	},
	"clear": function(){
		return {
			name: "clear", view: "ssheet-button", icon: "clear-styles",
			arrow: true, area: true, width: 58,
			options:getClearOperation()
		};
	},
	"image":function(){
		return {
			name:"image", view:"ssheet-button", icon:"add-image",
			arrow:true, options:[
				{id:"add-image-cell", value: webix.i18n.spreadsheet.labels["add-image-cell"]},
				{id:"add-image-top", value: webix.i18n.spreadsheet.labels["add-image-top"]}
			]
		};
	},
	"graph":function(){
		return {
			name:"graph", view:"ssheet-button", icon:"add-sparkline",
			arrow:true, options:[
				{id:"add-sparkline", value:webix.i18n.spreadsheet.labels["add-sparkline"]},
				{id:"add-chart", value: webix.i18n.spreadsheet.labels["add-chart"]}
			]
		};
	},
	"comment": function(){
		return {
			name: "comment",
			view: "ssheet-button", icon: "comments",
			arrow: true, area: true, width: 55
		};
	},
	"create-filter": function(){
		return ui.toggleButton({name: "create-filter"});
	},
	"hide-gridlines": function(){
		return ui.toggleButton({name: "hide-gridlines"});
	},
	"hide-headers": function(){
		return ui.toggleButton({name: "hide-headers"});
	},
	"show-formulas": function(){
		return ui.toggleButton({name: "show-formulas"});
	},
	"print-borders": function(){
		return ui.toggleButton({name: "print-borders", icon:"borders-no"});
	}
};