import {actions} from "./toolbar/actions";
import {defaultStyles} from "./toolbar/defaults";
import {toolbarButtons, getButtons} from "./toolbar/helpers";
import * as elm from "./toolbar/all_elements";
import {isCustom} from "./operations/formats";
import {getStyle, getFormat} from "./operations/styles";

const buttons = {
	"undo-redo": ["undo","redo"],
	"font": ["font-family","font-size","font-weight","font-style","underline","strike","color","background","borders"],
	"align": ["text-align","vertical-align","wrap","span","increase-indent","decrease-indent"],
	"format": ["format"]
};


export function init(view){
	view.attachEvent("onComponentInit", () => ready(view));
	view.attachEvent("onCommand", (action) => {
		if (action.id == "toolbar-update"){
			if(action.name)
				updateValue(view, action.name, action.value);
			else
				setValues(view, view.getSelectedId(true));
		}
	});

	let elements = [];

	if(view.config.toolbar){
		let allElements =  view.config.toolbar;
		if(allElements == "full"){
			allElements = elm.getAllElements(view.config.readonly);
			if(webix.isUndefined(view.config.bottombar))
				view.config.bottombar = true;
		}
		elements =   toolbarButtons(view, allElements);
	}
	else
		elements =   getButtons(view, view.config.buttons || buttons);

	const bar = {
		view: webix.env.$customScroll ? "scrollview" : "ssheet-toolbar-scrollview",
		scroll: "x",
		body: {
			view:"toolbar",
			css: "webix_ssheet_toolbar webix_layout_toolbar",
			id:"bar",
			padding:0,
			elements,
			on: {
				onChange: function (value, old, config){
					if(config == "user"){
						const source = this.$eventSource;
						const name = source.config.name;
						if(name == "format" && value == "common")
							value = "";

						const size = name == "font-size";
						if((size || name == "font-family") && (!value || (size && isNaN(value))))
							return source.setValue(old);
						else if(size){
							const maxSize = Math.floor(400 / 0.75); // 400 pt - max excel font size; divide 0.75 - pt to px conversion
							const limited = Math.max(1, Math.min(maxSize, value));
							if (limited != value){
								value = limited;
								source.setValue(value);
							}

							value += "px";
						}


						if(!(name == "format" && value == "custom"))
							view.callEvent("onStyleSet", [name, value]);
					}
				},
				onItemClick: function (id){
					var viewId = view.innerId(id);
					if (actions[viewId])
						actions[viewId].call(this, view);
					else
						view.callEvent("onCommand", [{ id: viewId }]);
				}
			}
		}
	};

	view.callEvent("onViewInit", ["toolbar", bar]);
	return bar;

}

function ready(view){
	view.attachEvent("onAfterSelect", (selected) => setValues(view, selected));
}

function setValues(view, selected) {
	const barStyles = getBarStyles(view, selected);
	view.$$("bar").setValues(barStyles);
}

function updateValue(view, name, value){
	const element = view.$$("bar").elements[name];

	if (element)
		element.setValue(value);
}

function getBarStyles(view, selected){
	const dropdowns = [
		"font-family",
		"font-size",
		"text-align",
		"vertical-align",
		"format",
		"background",
		"color"
	];

	const cell = selected[0];
	const barStyles = getCellStyles(view, cell);

	for(let i = 1; i < selected.length; i++){
		const cellStyle = getCellStyles(view, selected[i]);

		for(let k = dropdowns.length-1; k >= 0; k--){
			const property = dropdowns[k];
			if(barStyles[property] != cellStyle[property]){
				delete barStyles[property];
				dropdowns.splice(k, 1);
			}
		}

		if(!dropdowns.length)
			break;
	}

	return barStyles;
}

function getCellStyles(view, cell){
	const span = view.$$("cells").getSpan(cell.row, cell.column);
	if(span)
		[cell.row, cell.column] = span;

	const obj = getStyle(view, cell);
	const styles = obj ? webix.copy(obj.props) : {};

	for (let i in defaultStyles)
		if(!styles[i])
			styles[i] = defaultStyles[i];

	styles["font-size"] = styles["font-size"].replace("px", "");

	const format = getFormat(view, cell.row, cell.column) || "common";
	styles.format = isCustom(format) ? "custom" : format;

	return styles;
}
