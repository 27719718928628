import {image, chart} from "../mathematics/writer";
import {addMath} from "../mathematics/methods";

export function addImage(row, column, url){
	this.setCellValue(row, column, image(url));
	this.refresh();
}

export function addSparkline(row, column, config){
	let value = chart(config);
	this.setCellValue(row, column, value);
	this.refresh();
}

// getSheetData is deprecated, use ss.getCellValue(row, col, math, page), ss.getRangeValue(range, page) instead
export function getSheetData(name){
	if(this._mData.getPage(name))
		return {
			getValue: (r, c, math)=> this.getCellValue(r, c, math, name),
			getRangeValue: (range)=> this.getRangeValue(range, name)
		};
}

export function registerMathMethod(name, handler){
	addMath(this, name, handler, true);
}

export function recalculate(){
	this._mData.getStore().recalculate();
	this.callEvent("onMathRefresh",[]);
	this.refresh();
}

export function setPlaceholder(name, value){
	if(typeof name == "object"){
		value = name;
		name = "*";
	}

	this._mData.getStore().setPlaceholder(name, value);

	this.refresh();
	this.callEvent("onMathRefresh",[]);
}