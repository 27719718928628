import {getSheet} from "../sheets";
import {isCell, adress, range as getRange} from "../helpers/column_names";

function error(view, silent){
	if(!silent)
		view.alert({
			type: "alert-error",
			text: webix.i18n.spreadsheet.labels["error-link"]
		});
	return false;
}

export function showCells(view, ref, silent) {
	try {
		let r1, r2, c1, c2, sheet;

		let cellRef = ref.split("!");
		switch(cellRef.length){
			case 1:
				[cellRef] = cellRef;
				break;
			case 2:
				[sheet, cellRef] = cellRef;
				if(sheet.indexOf(" ") != -1 && (sheet[0] != "'" || sheet[sheet.length - 1] != "'"))
					return error(view, silent);
				break;
			default:
				return error(view, silent);
		}

		if(isCell(cellRef))
			[r1, c1] = adress(cellRef);
		else{
			const rangePos = getRange(ref, view);
			if(rangePos)
				[r1, c1, r2, c2, sheet] = rangePos;
			else
				return error(view, silent);
		}

		if(sheet){
			sheet = sheet.replaceAll("'", "");
			if(!getSheet(view, sheet))
				return error(view, silent);
			if(sheet != view.getActiveSheet())
				view.showSheet(sheet);
		}

		const grid = view.$$("cells");
		grid.showCell(r1, c1);
		grid.addSelectArea(
			{ row: r1, column: c1 },
			{ row: r2 || r1, column: c2 || c1 }
		);

		return true;
	}
	catch(err){
		return error(view, silent);
	}
}