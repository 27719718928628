import {changeAreaDecimals} from "../operations/formats";

export const actions = {
	span: view => {
		const range = view.$$("cells").getSelectArea();
		if(range){
			if(isMerged(view,range))
				view.splitCell();
			else {
				const values = [];
				for(let r = range.start.row; r<=range.end.row; r++){
					for(let c = range.start.column; c<=range.end.column; c++){
						let value = view.getCellValue(r,c);
						if(value) values.push(value);
						if(values.length >1) break;
					}
					if(values.length >1) break;
				}
				if(values.length>1){
					view.confirm({
						text:webix.i18n.spreadsheet.labels["merge-cell-confirm"]
					}).then(()=> view.combineCells() );
				}
				else
					view.combineCells();
			}
		}
	},
	undo: view => view.undo(),
	redo: view => view.redo(),
	"hide-gridlines": view => view.hideGridlines("toggle"),
	"hide-headers": view => view.hideHeaders("toggle"),
	"freeze-columns": view => view.freezeColumns(),
	"freeze-rows": view => view.freezeRows(),
	"increase-decimals": view => changeAreaDecimals(view, 1),
	"decrease-decimals": view => changeAreaDecimals(view, -1),
	"increase-indent": view => view.callEvent("onStyleSet", ["indent", 1]),
	"decrease-indent": view => view.callEvent("onStyleSet", ["indent", -1]),
	"show-formulas": view => view.showFormulas("toggle"),
	"print-borders": view => view.showPrintBorders("toggle"),
};

function isMerged(view,range){
	var i, j,
		c0 = range.start,
		c1 = range.end;

	for( i = c0.row*1; i <= c1.row*1; i++ ){
		for( j = c0.column*1; j <= c1.column*1; j++ ){
			if(view.$$("cells").getSpan(i, j))
				return true;
		}
	}
	return false;
}
