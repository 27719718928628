import {Dialog} from "./common";

export const action = "add-link";

export class DialogBox extends Dialog{
	$show(box, form){
		const cell = this.view.getSelectedId();
		if(!cell)
			return false;
		if (!this.restoreValue(form))
			form.clear();
		form.elements.name.focus();

		this.view.$handleSelection = (st, end, a)=>{
			form.elements.url.setValue("#"+a);
			return false;
		};
	}
	$hide(){
		this.view.$handleSelection = null;
	}
	restoreValue(form){
		const cell = this.view.getSelectedId();
		if (cell){
			const value = this.view.getCellValue(cell.row, cell.column);
			if (value && typeof value == "string" && value.indexOf("=HYPERLINK") === 0){
				const parts = value.split("\"");
				form.setValues({
					name: (parts[3] || ""),
					url: (parts[1] || "")
				});
				return true;
			}
		}
		return false;
	}
	$init(){
		return {
			view: "ssheet-dialog",
			head: webix.i18n.spreadsheet.labels["link-title"],
			move: true,
			position: "center",
			body:{
				view:"form",
				elements:[
					{ view:"text", name:"name",  placeholder:webix.i18n.spreadsheet.labels["link-name"]},
					{ view:"text", name:"url",  placeholder:webix.i18n.spreadsheet.labels["link-url"]}
				]
			},
			on:{
				onSaveClick: () => this.okClick(),
				onCancelClick: () => this.close()
			}
		};
	}
	okClick(){
		const cell = this.view.getSelectedId();
		const data = this.$dialog.getBody().getValues();

		let text = "";
		if (data.url){
			data.name = data.name || data.url;
			if (!/^(#|(https?:\/\/))/i.test(data.url))
				data.url = "http://" + data.url;
			text = `=HYPERLINK("${data.url}","${data.name}")`;
		}

		this.view.setCellValue(cell.row, cell.column, text);
		this.view.refresh();
		this.close();
	}
}