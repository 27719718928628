import {isVisible} from "../sheets";

export function print(view, options, htmlOnly){
	const views = [];
	const order = {};
	const active = view._activeSheet;

	const html = _buildHtml(view, options, active, views, order);
	view.showSheet(active);

	const promise = webix.promise.defer();
	webix.promise.all(views).then(res =>{
		const keys = Object.keys(order).sort( view.$$("cells").data.sorting.as.int );
		for (let i = keys.length - 1; i >= 0; i--) {
			const index = keys[i];
			html.insertBefore(res[index], html.childNodes[ order[index] ]);
		}

		if(htmlOnly)
			return promise.resolve(html);

		webix.html.insertBefore(html, options.docFooter, document.body);
		window.print();
		_cleanHtml(view, html);

		promise.resolve();
	});
	return promise;
}

export function init(view){
	view.$customPrint = function(options, htmlOnly){
		return print(view, options, htmlOnly);
	};

	view._printBorders = {
		paper:"A4",
		fit:"page",
		mode:"landscape",
		margin:1,
		sheetnames:1
	};

	view.attachEvent("onUndo", (action, row, column, value) => {
		if (action == "print-borders")
			view.showPrintBorders(value);
	});

	view.attachEvent("onAfterSheetShow", ()=> showBorders(view, true));
	view.attachEvent("onChange", ()=> showBorders(view, true));
}

function _cleanHtml(view, html){
	webix.html.remove(html);

	view._sheets.forEach((sheet, i) => {
		webix.html.removeStyle(".wss_"+view.$index+"_"+i);
	});
}
function _buildHtml(view, options, active, views, order){
	let doc = webix.html.create("div", { "class":"webix_ssheet_print"});

	options.xCorrection = 1;
	options.header = options.header || false;
	options.trim = webix.isUndefined(options.trim) ? true : options.trim;
	options.sheetnames = webix.isUndefined(options.sheetnames) ? true : options.sheetnames;
	const baseIndex = view.$index;
	const pageSize = getPageSize(options);

	let sheets = options.sheets;
	
	// backward compatibility, options.data can be only "selection"
	// "current" and "all" are deprecated
	const data = options.data;

	if(!sheets || data == "current" || data == "selection"){
		sheets = [active];
	}
	else if(typeof sheets == "string")
		sheets = [sheets];
	else if(sheets === true || data == "all")
		sheets = view._sheets.map(sheet => sheet.name);

	sheets = sheets.filter(sheet => isVisible(view, sheet));

	sheets.forEach((name, i) => {
		view.$index = baseIndex+"_"+i;
		const grid = view.$$("cells");

		view.showSheet(name);
		let prefix = "wss_"+view.$index;

		view._print = true;
		let table = grid.$customPrint(options, true);
		delete view._print;

		const tbl = table.firstChild;
		const [header, body] = tbl.children;

		const topSplit = header.childElementCount;
		const sheetName = options.sheetnames ? 20 : 0;
		let splitHeight = sheetName;

		for (let s = 1; s <= topSplit; s++)
			splitHeight += grid.getItem(s).$height || grid.config.rowHeight;

		if(splitHeight > pageSize.height / 4)
			for (let i = topSplit - 1; i >= 0; i--){
				if(body.firstChild)
					webix.html.insertBefore(header.children[i], body.firstChild);
				else
					body.appendChild(header.children[i]);
			}

		if(sheetName){
			const sheetNode = webix.html.create("tr", {class:"webix_ssheet_sheetname"}, `<td>${name}:</td>`);

			if(header.firstChild)
				webix.html.insertBefore(sheetNode, header.firstChild);
			else
				header.appendChild(sheetNode);
		}

		//for empty sheet we don't have any HTML
		const node = table.firstChild;
		if (node && name != active){
			webix.html.removeCss(node, "wss_"+baseIndex);
			webix.html.addCss(node, prefix);
		}

		doc.appendChild(table);

		if(options.external)
			for(let id in view.views._pull){
				const innerView = view.views._pull[id];
				if(innerView.type == "image"){
					const img = webix.html.create("IMG", {
						src: innerView.data,
						width: (innerView.config.width || 500)+"px",
						height: (innerView.config.height || 300)+"px"
					});

					const promise = webix.promise.defer();
					views.push(promise);
					img.onload = ()=> promise.resolve();

					doc.appendChild(img);
				}
				else{
					const externalUI = webix.$$(id).getBody();
					if(externalUI.$customPrint){
						const customPrint = externalUI.$customPrint({size: options.size}, true);
						if(customPrint.then){
							order[views.length] = doc.childElementCount;
							views.push(customPrint);
						}
						else
							doc.appendChild( customPrint );
					}
					else
						doc.appendChild( printInnerView(webix.$$(id).getBody()) );
				}
			}

		if(i+1 < view._sheets.length){
			const br = webix.html.create("DIV", {"class":"webix_print_pagebreak"});
			doc.appendChild(br);
		}
	});

	view.$index = baseIndex;
	return doc;
}

function printInnerView(view){
	const body = view.$view.cloneNode(true);

	//copy data from all canvases
	const canvases = view.$view.getElementsByTagName("canvas");
	if(canvases.length)
		for(let i = canvases.length-1; i >=0; i--){
			const destCtx = body.getElementsByTagName("canvas")[i].getContext("2d");
			destCtx.drawImage(canvases[i], 0, 0);
		}

	const container = webix.html.create("div", {class:"webix_ssheet_ui"});
	container.appendChild(body);

	return container;
}

function getPageSize(options){
	const sizes = {
		"A3": { width: 11.7, height: 16.5 },
		"A4": { width: 8.27, height:11.7 },
		"letter": { width: 8.5, height:11 }
	};

	const size = sizes[options.paper];
	const landscape = options.mode == "landscape";

	const margin = options.margin ? 2 * webix.env.printMargin : 0;

	return {
		width: size[landscape ? "height" : "width"] * webix.env.printPPI - margin,
		height: size[landscape ? "width" : "height"] * webix.env.printPPI - margin
	};
}

export function showBorders(view, redraw){
	if(redraw && !view._printBorders.$render)
		return;

	const borders = {rows:{}, cols:{}};
	const grid = view.$$("cells");
	const options = view._printBorders;

	const pageSize = getPageSize(options);

	const sheetName = options.sheetnames ? 20 : 0;
	let topSplit = grid.config.topSplit;
	let splitHeight = sheetName;

	for (let s = 1; s <= topSplit; s++)
		splitHeight += grid.getItem(s).$height || grid.config.rowHeight;

	if(splitHeight > pageSize.height / 4){
		topSplit = 0;
		splitHeight = sheetName;
	}

	let rowsHeight = splitHeight;
	for (let r = topSplit + 1; r <= view.config.rowCount; r++) {
		const height = grid.getItem(r).$height || grid.config.rowHeight;

		if(rowsHeight + height > pageSize.height){
			rowsHeight = splitHeight;
			borders.rows[r == 1 ? r : r-1] = 1;
		}

		rowsHeight += height;
	}

	if(options.fit != "page"){
		let colsWidth = 0;
		for (let c = 1; c <= view.config.columnCount; c++) {
			const width = grid.getColumnConfig(c).width || grid.config.columnWidth;

			if(colsWidth + width > pageSize.width){
				colsWidth = 0;
				borders.cols[c == 1 ? c : c-1] = 1;
			}

			colsWidth += width;
		}
	}

	view._printBorders.$render = borders;
	view.refresh();
}