export const decode = {};
export const encode = {};

const alpha = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

function indexToHeader(index){
	const chars = alpha.length;
	const quotient = Math.floor(--index / chars);
	if (quotient > 0)
		return indexToHeader(quotient) + alpha[index % chars];

	return alpha[index % chars];
}

export function updateMaxColumn(maxColumn){
	const lastColumn = Object.keys(encode).length;

	for(let i = lastColumn + 1; i <= maxColumn; i++){
		let str = indexToHeader(i);

		decode[str] = i;
		encode[i] = str;
	}
}

export function adress(text){
	let size;
	for(let i = 0; i < text.length; i++)
		if(text.charCodeAt(i) < 59){
			size = i;
			break;
		}

	const col = text.substr(0, size);
	const row = text.substr(size);

	return [row*1, decode[col]];
}

export function range(text, view, page){
	const nind = text.indexOf("!");
	let sheet = page || "";

	//parse sheet names
	if (nind !== -1){
		sheet = text.substr(0, nind);
		if (sheet[0] === "'")
			sheet = sheet.substr(1, sheet.length-2);
		text = text.substr(nind+1);
	}

	let parts = text.split(":");
	if (parts.length != 2){
		if(view){
			let code = view.ranges.getCode(text, sheet);
			if(!code){
				// global named range
				code = view.ranges.getCode(text, true);
				if(!code)
					return null;
			}

			const rangeParts = code.split("!");
			if(rangeParts[0] != page || view.getActiveSheet())
				sheet = rangeParts[0].replace(/'/g,"");
			code = rangeParts[1];
			parts = code.split(":");
		}
		else
			return null;
	} 

	const d1 = adress(parts[0]);
	const d2 = adress(parts[1]);

	if(d1[0] && d1[1] && d2[0] && d2[1])
		return [d1[0], d1[1], d2[0], d2[1], sheet];
}


export function escapeSheet(sheet){
	return sheet.indexOf(" ") == -1 ? sheet : `'${sheet}'`;
}

export function toRange(x,y,x2,y2,sheet){
	return (sheet?(escapeSheet(sheet)+"!"):"")+encode[y]+x+":"+encode[y2]+x2;
}
export function toSheetRange(x,y,sheet){
	return (sheet?(escapeSheet(sheet)+"!"):"")+x+":"+y;
}

export function rangeObj(text, view){
	if (typeof text === "object") return text;
	const a = range(text, view);
	return { start:{ row:a[0], column:a[1]}, end:{ row:a[2], column:a[3] }, sheet: a[4] };
}

export function eachRange(text, view, callback, data){
	const range = rangeObj(text);
	for (let row = range.start.row; row <= range.end.row; row++) {
		for (let column = range.start.column; column <=  range.end.column; column++) {
			callback(view, {row: row, column: column}, data);
		}
	}
}

export function isRange(text){
	if(typeof text == "string"){
		const cells = text.split(":");
		if(cells.length == 2){
			for(let i = 0; i < cells.length; i++){
				const parts = cells[i].split("!");
				if(!isCell(parts.pop()))
					return;
			}
			return true;
		}
	}
}

const cellTest = /^[A-Z]+[0-9]+$/;
export function isCell(text){
	return cellTest.test(text);
}

export function changeRange(text,name,inc,start){
	let updated = false;

	if(isRange(text)){
		let [r1,c1,r2,c2,sheet] = range(text);

		//ranges like B2:A1
		if(r1 > r2)
			[r1, r2] = [r2, r1];
		if(c1 > c2)
			[c1, c2] = [c2, c1];

		if (name === "row" && start.row <= r2){
			if((inc < 0 && start.row < r1) || (inc > 0 && start.row <= r1))
				r1 += inc;
			r2 += inc;

			if(r2<r1)
				return "";

			updated = true;
		}
		else if (name === "column" && start.column <= c2){
			if((inc < 0 && start.column < c1) || (inc > 0 && start.column <= c1))
				c1 += inc;
			c2 += inc;

			if(c2<c1)
				return "";

			updated = true;
		}
		if(updated)
			text = toRange(r1,c1,r2,c2,sheet);
	}

	return text;
}