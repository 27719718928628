export function confirm(config){
	return webix.confirm({
		container: this.$view,
		css:"webix_ssheet_confirm",
		text:config.text,
		ok: webix.i18n.spreadsheet.labels.ok,
		cancel: webix.i18n.spreadsheet.labels.cancel
	});
}

export function alert(config){
	return webix.alert({
		container: this.$view,
		css: "webix_ssheet_alert",
		text:config.text,
		type:config.type,
		ok: webix.i18n.spreadsheet.labels.ok
	});
}