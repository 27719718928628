import { style_names } from "../operations/styles";
import { fontFamily} from "../toolbar/buttons";
import { defaultStyles} from "../toolbar/defaults";
import { formatCache, formatsCount } from "../operations/formats";
import { find } from "../helpers/array";

export function init(view){
	view._parseExcel = function(obj, driver){
		const options = obj.options || {};
		options.math = view.config.math;

		const full = { sheets:[]};
		const fmt = {count: formatsCount, cache:{}};
		for(let i  = 0; i< obj.names.length; i++){
			options.name = obj.names[i];
			full.sheets.push({
				name:options.name,
				state:obj.states[i],
				content:_excel_to_data(driver.getSheet(obj, options), obj.ranges, i, fmt)
			});
		}
		return full;
	};
	view._parseCsv = function(obj, driver){
		obj = driver.getRecords(obj);

		const dataObj = webix.copy(obj);
		const data = [];

		for(let i = 0; i < dataObj.length; i++){
			let item = dataObj[i].split(driver.cell);
			for(let c  = 0; c<item.length; c++){
				const row = i+1, column = c+1;
				data.push([row, column, item[c]]);
			}
		}
		return { data };
	};
}

function _excel_to_data(obj, ranges, index, fmt){
	const dataObj = webix.copy(obj.data);
	const data =  [];

	for (let i = 0; i < dataObj.length; i++){
		for(let c = 0; c<dataObj[0].length; c++){
			const row = i+1, column = c+1;
			data.push([row, column, dataObj[i][c]]);
		}
	}
	obj.data = data;

	//merged cells
	if(obj.spans){
		for(let i = 0; i<obj.spans.length; i++){
			obj.spans[i][0]++;
			obj.spans[i][1]++;
		}
	}

	//named ranges
	if(ranges){
		for(let r = 0; r < ranges.length; r++){
			const sheetIndex = ranges[r].Sheet;
			const book = webix.isUndefined(sheetIndex);
			if(book || sheetIndex === index){
				obj.ranges = obj.ranges || [];
				obj.ranges.push([ranges[r].Name.toUpperCase(), ranges[r].Ref.substring(ranges[r].Ref.indexOf("!")+1).replace(/\$/g, ""), book]);
			}
		}
	}

	//cell styles
	if(obj.styles)
		_getStyles(obj, fmt);

	if(obj.types)
		_getTypes(obj);

	if(obj.hidden){
		_getHidden(obj);
	}

	//column width and row height
	if(obj.sizes){
		for(let i = 0; i<obj.sizes.length; i++){
			if(obj.sizes[i][0] == "column")
				obj.sizes[i] = [0, obj.sizes[i][1]+1, obj.sizes[i][2]];
			else
				obj.sizes[i] = [obj.sizes[i][1]+1, 0, obj.sizes[i][2]];
		}
	}

	return obj; 
}

//ARGB conversion
function _safeColor(str){
	str = str || "000000";
	if(str.length === 8) str = str.substring(2);
	return "#"+str;
}

//only fonts that we support can be imported
function _safeFont(str){
	let safe = defaultStyles["font-family"];
	for(let i = 0; i<fontFamily.length; i++){
		if(fontFamily[i].value == str) { safe = fontFamily[i].id; break; }
	}
	return safe;
}

function _getTypes(obj){
	obj.types.forEach(type => {
		for(let i = 0; i < obj.data.length; i++){
			if(obj.data[i][0] === type[0]+1 && obj.data[i][1] === type[1]+1){
				obj.data[i][4] = type[2];
				break;
			}
		}
	});
}

function _getHidden(obj){
	const hidden = {
		row:[],
		column:[]
	};

	for(let i = 0; i < obj.hidden.length; i++){
		const item = obj.hidden[i];
		if(item[0] == "column")
			hidden.column.push(item[1]+1);
		else
			hidden.row.push(item[1]+1);
	}

	obj.table = {hidden};
}

function _getBorderStyles(pos, stl){
	pos = pos.replace("border-", "");
	if(stl.border && stl.border[pos]){
		const border = stl.border[pos];
		if(border.color){
			const color = _safeColor(border.color.rgb) || "";
			const type = border.style || "";
			return `${color},${type}`;
		}
	}
	return "";
}

function _getStyles(obj, fmt){
	let stylescount = 1;
	const styleshash = {}, styles = [], formats = [];

	for(let i = 0; i<obj.styles.length; i++){
		let str = [], stl = obj.styles[i][2], css;

		for(let s  = 0; s<style_names.length; s++){
			switch (style_names[s]){
				case "color": 
					str[s] = stl.font && stl.font.color ? (_safeColor(stl.font.color.rgb) || "") : "";
					break;
				case "background":
					str[s] = stl.fill && stl.fill.fgColor ? (_safeColor(stl.fill.fgColor.rgb) || "") : "";
					break;
				case "text-align":
					str[s] = (stl.alignment ? stl.alignment.horizontal : "") || defaultStyles["text-align"];
					break;
				case "font-family":
					str[s] = stl.font && stl.font.name ? _safeFont(stl.font.name) : "";
					break;
				case "font-size":
					str[s] = stl.font && stl.font.sz ? ((stl.font.sz/0.75)+"px" || "") : ""; //pt to px conversion
					break;
				case "font-style":
					str[s] = stl.font && stl.font.italic ? "italic" : "";
					break;
				case "underline":
					str[s] = stl.font && stl.font.underline ? "underline" : "";
					break;
				case "strike":
					str[s] = stl.font && stl.font.strike ? "strike" : "";
					break;
				case "font-weight":
					str[s] = stl.font && stl.font.bold ? "bold" : "";
					break;
				case "vertical-align":{
					const va = stl.alignment ? (stl.alignment.vertical || "") : "";
					str[s] = (va == "center" ? "middle" : va) || defaultStyles["vertical-align"];
					break;
				}
				case "wrap":
					str[s] = stl.alignment && stl.alignment.wrapText ? "wrap" : "nowrap";
					break;
				case "borders":
					str[s] = "";
					break;
				case "format":{
					let format = stl.numFmt;
					let name = "";
					if(format && format != "General"){
						format = format.replaceAll("\\", "");
						if(formatCache[format])
							name = formatCache[format];
						else if(fmt.cache[format])
							name = fmt.cache[format];
						else
							name = fmt.cache[format] = "fmt"+(fmt.count++);

						if(!find(formats, arr => arr[0] == name))
							formats.push([name, format]);
					}
					str[s] = name;
					break;
				}
				case "border-right":
				case "border-bottom":
				case "border-left":
				case "border-top":
					str[s] = _getBorderStyles(style_names[s], stl);
					break;
				case "indent":
					str[s] = stl.alignment && stl.alignment.indent ? stl.alignment.indent : "";
					break;
			}
		}
		str = str.join(";");
		css = styleshash[str] || "wss"+stylescount;

		for(let d = 0; d<obj.data.length; d++){
			if(obj.data[d][0] === obj.styles[i][0]+1 && obj.data[d][1] === obj.styles[i][1]+1){
				obj.data[d][3] = css;
				break;
			}
		}
		if(!styleshash[str]){
			styles.push([css, str]);
			styleshash[str] = css;
			stylescount++;
		}
	}

	obj.formats = formats;
	obj.styles = styles;
}