const defaultMenu = [
	{ id:"file", submenu:[
		{id: "sheet", submenu: [
			{id: "new-sheet"},
			{id: "copy-sheet"},
			{id: "remove-sheet"},
			{id: "hide-sheet"}
		]},
		{id: "excel-import"},
		{id: "excel-export"},
		{id: "print"},
		{id: "print-borders"}
	]},
	{ id:"edit", submenu:[
		{id: "add-range"},
		{id: "add-dropdown"},
		{id: "add-link"},
		{id: "lock-cell"},
		{id: "conditional-format"},
		{id: "clear", submenu:[
			{id: "clear-value"},
			{id: "clear-style"},
			{id: "clear-conditional-formats"},
			{id: "clear-dropdown-editors"},
			{id: "clear-comments"},
			{ $template:"Separator" },
			{id: "clear-all"}
		]}
	]},
	{ id:"insert", submenu:[
		{id: "image", submenu:[
			{ id: "add-image-cell"},
			{ id: "add-image-top"}
		]},
		{id: "graph", submenu:[
			{ id: "add-sparkline"},
			{ id: "add-chart"}
		]},
		{id: "add-comment"}
	]},
	{ id:"data", submenu:[
		{id: "sort", submenu: [
			{id: "sort-asc"},
			{id: "sort-desc"}
		]},
		{id: "create-filter"}
	]},
	{ id: "view", submenu:[
		{id: "columns", submenu: [
			{id: "insert-column"},
			{id: "delete-column"},
			{id: "show-column"},
			{id: "hide-column"},
			{id: "resize-column"}
		]},
		{id: "rows", submenu: [
			{id: "insert-row"},
			{id: "delete-row"},
			{id: "show-row"},
			{id: "hide-row"},
			{id: "resize-row"}
		]},
		{ $template:"Separator" },
		{id: "freeze-columns"},
		{id: "freeze-rows"},
		{id: "hide-gridlines"},
		{id: "hide-headers"},
		{id: "show-formulas"}
	]}
];

const readonlyMenu = [
	{ id:"file", submenu:[
		{id: "excel-export"},
		{id: "print"},
		{id: "print-borders"}
	]}
];

const ui = {
	getMenuData: function(menu, config){
		var i;
		for(i=0; i < menu.length; i++){
			if(typeof menu[i] == "string")
				menu[i] = {id: menu[i]};
			if((menu[i].id == "resize-column" || menu[i].id == "resize-row") && !config.resizeCell){
				menu.splice(i,1);
				i--;
				continue;
			}
			if(!menu[i].value)
				menu[i].value = webix.i18n.spreadsheet.menus[menu[i].id];
			if(menu[i].submenu)
				menu[i].submenu = this.getMenuData(menu[i].submenu, config);
		}

		return menu;
	}
};

export function init(view){
	view.attachEvent("onComponentInit", () => ready(view));

	const config = view.config;
	const menu = {
		view: "menu",
		borderless: true,
		css: "webix_ssheet_menu",
		id: "menu",
		autowidth: true,
		type:{
			height: 40
		},
		data: ui.getMenuData(
			webix.isArray(config.menu) ? config.menu : webix.copy(config.readonly ? readonlyMenu : defaultMenu),
			config
		)
	};

	view.callEvent("onViewInit", ["menu", menu]);
	return menu;
}


function ready(view){
	if(view.$$("menu"))
		view.$$("menu").attachEvent("onMenuItemClick",(id) => callAction(view,id));
}

const actionMap = {
	"undo": view => view.undo(),
	"redo": view => view.redo(),
	"insert-column": view => view.insertColumn(),
	"delete-column": view => view.deleteColumn(),
	"show-column": view => view.callEvent("onCommand",[{id: "show", group: "column", neighbors:true}]),
	"hide-column": view => view.hideColumn(null,true),
	"resize-column": view => view.callEvent("onCommand",[{
		id: "resize", group: "column", value: webix.i18n.spreadsheet.menus["resize-column"]
	}]),
	"insert-row": view => view.insertRow(),
	"delete-row": view => view.deleteRow(),
	"show-row": view => view.callEvent("onCommand",[{id: "show", group: "row", neighbors:true}]),
	"hide-row": view => view.hideRow(null,true),
	"resize-row": view => view.callEvent("onCommand",[{id: "resize", group: "row", value: webix.i18n.spreadsheet.menus["resize-row"]}]),
	"freeze-columns": view => view.freezeColumns(),
	"freeze-rows": view => view.freezeRows(),
	"hide-gridlines": view => view.hideGridlines("toggle"),
	"hide-headers": view => view.hideHeaders("toggle"),
	"show-formulas": view => view.showFormulas("toggle"),
	"print-borders": view => view.showPrintBorders("toggle")
};

function callAction(view,id){
	if(view.callEvent("onMenuItemClick",[id])){
		if(actionMap[id])
			actionMap[id](view);
		else
			view.callEvent("onCommand",[{id: id}]);
	}
}