import * as flt from "../operations/filter";

export function setCellFilter(row, column, filter){
	const line = this._table._ssFilters[row] = this._table._ssFilters[row] || {};
	const value = line[column] || null;

	if (filter && (webix.isArray(filter) || typeof filter == "string"))
		filter = { options:filter };
	line[column] = filter;

	if (filter && typeof filter == "object"){
		this._table.addCellCss(row,column, "ss_filter");
		if (filter.value && flt.isValueActive(filter.value))
			this._table.addCellCss(row,column, "ss_filter_active");

		webix.extend(filter, {row, column}, true);
		filter.options = flt.calibrateRange(this, row, column, filter.options);

		if (!filter.handler && filter.value && filter.value.condition){
			const mode = filter.mode || flt.getFilterMode(this, row, column);
			filter.handler = flt.getFilterFunction(mode, filter.value);
		}
	} else {
		delete this._table._ssFilters[row][column];
		this._table.removeCellCss(row,column, "ss_filter");
		this._table.removeCellCss(row,column, "ss_filter_active");
	}
	this.callEvent("onAction", ["filter", { row, column, value, newValue:filter }]);
}

export function getCellFilter(row, column){
	let line = this._table._ssFilters[row] || {};
	return line[column] || null;
}

export function removeFilters(){
	const filters = flt.getFilters(this._table);

	for	(let i=0; i<filters.length; i++){
		const {row, column} = filters[i];
		this.setCellFilter(row, column, null);
	}

	this._table._ssFilters = {};
	this._table.data.filter((obj) => {
		return this.isRowVisible(obj.id);
	});
}


export function filterSpreadSheet(){
	const grid = this._table;
	grid.data.silent(()=>{
		// hidden rows
		grid.data.filter(obj => this.isRowVisible(obj.id));

		const filters = flt.getFilters(grid);
		for (let i = 0; i < filters.length; i++){
			const {row, column, handler, lastRow} = filters[i];

			let finished = false;
			grid.data.filter(obj => {
				let r = obj.id;
				if(r <= row || typeof handler != "function")
					return true;

				const span = grid.getSpan(r, column);
				if(span){
					if(span[0] == row && span[1] == column)
						return true;
					else
						r = span[0];
				}

				const value = String(this.getCellValue(r, column, false));

				if(lastRow){
					if(r > lastRow)
						return true;
				}
				else if(finished || !value)
					return finished = true;

				return handler(value);
			}, 1, true);
		}
	});

	grid.callEvent("onAfterFilter", []);
	grid.refresh();
}
