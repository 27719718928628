import {range, toRange, changeRange, encode, isRange} from "../helpers/column_names";
import {ignoreUndo} from "./undo";
import * as arr from "../helpers/array";

export function init(view){
	const grid = view._table.$view.querySelector(".webix_ss_center");

	view.views = {
		register:function(type, render, track){
			if(!this._types[type])
				this._types[type] = { render:render, track:track };
		},
		move: function(id, x, y){
			const win = webix.$$(id);
			const old = webix.copy(this._pull[id]);
			this._pull[id].x = _correctPos(view, win, x, "x");
			this._pull[id].y = _correctPos(view, win, y, "y");
			view.callEvent("onAction", ["move-view", { row:id, newValue:webix.copy(this._pull[id]), value:old} ]);
			_showOne(view, win, this._pull[id]);
		},
		add: function(x, y, type, data, config, id){
			config = config || {};
			if(type == "chart"){
				config = webix.extend(config, {
					type: "line",
					dataSeries:"columns"
				});
			}

			const height =  config.height || 300;
			const width = config.width || 500;

			const win = webix.ui({ 
				id:id || "$ssheet-ui"+webix.uid(), // fixed id is for undo only
				view: "ssheet-ui",
				container:grid, master:view._table.config.id,
				width:width, height:height
			});

			win.attachEvent("onViewMoveEnd", (x, y) => {
				const state = view._table.getScrollState();
				this.move(id, x, y+state.y);
			});
			win.attachEvent("onViewResize", () => {
				const conf =  this._pull[id];
				const old = webix.copy(conf);

				conf.config.height = win.$height;
				conf.config.width = win.$width;
				view.callEvent("onAction", ["resize-view", { row:id, newValue:webix.copy(conf), value:old } ]);
			});
			win.attachEvent("onViewEdit", () => {
				const config = this._pull[id];
				const command = this._commands[config.type] || config.type;
				view.callEvent("onCommand", [{ id:command, viewid:id, config }]);
			});
			win.attachEvent("onViewRemove", () => this.remove(id));

			id = win.config.id;

			x = _correctPos(view, win, x, "x");
			y = _correctPos(view, win, y, "y");

			this._pull[id] = { x, y, type, data, config };
			this.update(id, data, config, true);
			_showOne(view, win, this._pull[id]);

			return id; 
		},
		remove: function(id){
			const old = this._pull[id];
			webix.$$(id).close();
			delete this._pull[id];

			view.callEvent("onAction", ["add-view", { row:id, newValue:null, value:old } ]);
		},
		get:function(id){
			return webix.$$(id).getBody();
		},
		getConfig:function(id){
			return this._pull[id];
		},
		update:function(id, data, config, inner){
			const old = inner ? null : webix.copy(this._pull[id]);
			const conf = this._pull[id];
			const actions = this._types[conf.type];

			data = data || config.data || "";
			config = config || {};

			delete config.data;

			if(conf.type == "chart"){
				const type = config.type;
				if(type && !config.series){
					const xAxis = config.xAxis && config.xAxis.range;
					const legend = config.legend && config.legend.range;

					let oldLeftColumn;
					if(old && old.config){
						if(_isPie(type)){
							if(old.config.legend && old.config.legend.fromData)
								oldLeftColumn = 1;
						}
						else if(old.config.xAxis && old.config.xAxis.fromData)
							oldLeftColumn = 1;
					}

					config.series = _getSeries(view, data, config.dataSeries, {xAxis, legend}, oldLeftColumn);
				}
			}

			const win = webix.$$(id);

			const height = config.height || 300;
			const width = config.width || 500;

			if(win.$width != width && win.$height != height){
				win.define({width, height});
				win.resize();
			}

			conf.data = data;
			conf.config = config;

			let node = this.get(id);

			data = _fromRange(view, conf);

			if(actions.render){
				const renderConfig = webix.copy(conf);

				//clear width and height for inner view resizing
				renderConfig.config.width = renderConfig.config.height = 0;

				node = actions.render(node, renderConfig, data);
				_processTables(node);
			}

			if(actions.track)
				_trackOne(view, node, actions.track, conf);

			view.callEvent("onAction", ["add-view", { row:id, newValue:webix.copy(this._pull[id]), value:old} ]);
		},
		highlight:function(id){
			const win = webix.$$(id);
			const css = "webix_ssheet_ui_focused";

			const oldWin = webix.$$(this._activeWin);
			if(oldWin && !oldWin.$destructed)
				webix.html.removeCss(oldWin.$view, css);

			if(win){
				this._activeWin = id;
				webix.html.addCss(win.$view, css);
			}
			else
				delete this._activeWin;
		},
		_commands:{
			"image":"add-image-top",
			"chart":"add-chart"
		},
		_types:{},
		_pull:{}
	};

	// handle events
	view._table.attachEvent("onResize", () => _showAll(view) );
	view._table.attachEvent("onScrollY", () => _showAll(view) );
	view._table.attachEvent("onScrollX", () => _showAll(view) );

	view.attachEvent("onReset", () => _reset(view));
	view.attachEvent("onUndo", (type, row, column, value) => {
		const move = type === "move-view";
		if (move || type == "resize-view" || type === "add-view")
			_undoUI(view, move, row, value);
	});
	view.attachEvent("onAction", (action, p) => {
		if(action == "header-hide")
			_showAll(view);
		else if(action == "before-grid-change")
			_updateData(view, p.name, p.inc, p.data, p.start);
	});
	view.attachEvent("onColumnOperation", () =>  _showAll(view, true));
	view.attachEvent("onRowOperation", () => _showAll(view, true));
	view.attachEvent("onDataParse", (data) => _parse(view, data));
	view.attachEvent("onDataSerialize", (data, config) => _serialize(view, data, config));
	view.attachEvent("onCellChange", (r,c,v,p) => {
		if(p == view.getActiveSheet())
			_trackAll(view);
	});
	view.attachEvent("onMathRefresh", () => _trackAll(view));

	view.attachEvent("onDestruct", () => {
		for(let i in view.views._pull) webix.$$(i).destructor();
	});

	let editUIType;
	webix.attachEvent("onClick", function(ev){
		let focus = webix.$$(ev);
		if(focus && focus.config.externalUI)
			focus = webix.$$(focus.config.externalUI);

		const ui = focus && (focus.queryView("ssheet-ui","self") || focus.queryView("ssheet-ui","parent"));

		if(ui){
			view.views.highlight(ui);
			const id = ui.config.id;

			if(editUIType){
				if(view.views.getConfig(id).type == editUIType)
					ui.callEvent("onViewEdit", []); //start editing if any editor is open
				else
					view.callEvent("onCommand", [{ id:"close-ui-editor" }]);
			}
		}
		else if(!editUIType)
			view.views.highlight();
	});

	view.attachEvent("onUIEditStart", function(id, type){
		view.views.highlight(id);
		editUIType = type;
	});

	view.attachEvent("onUIEditStop", function(id, type){
		if(editUIType == type)
			webix.delay(()=>{ // after onClick handler (to save focus)
				editUIType = null;
			});
	});

	// set default views
	view.views.register("chart", (node, conf, data) => {
		const dataRange = _getNames(view, conf);
		const config = _genChartConfig(conf, data, dataRange);
		return webix.ui(config, node);
	}, (view, data) => {
		view.clearAll();
		view.parse(data);
	});

	view.views.register("image", (node, conf, data) => {
		const config = webix.extend({ css:"webix_ssheet_bgimage", template:`<img src="${data}"/>` }, conf.config||{}, true );
		return webix.ui(config, node);
	});
}

function _parse(view, data){
	const views = data.views;
	if(views){
		ignoreUndo(function(){
			views.forEach(obj => view.views.add.apply(view.views, obj));
		}, view);
	}
}

function _serialize(view, data, config){
	data.views = [];
	for(let i in view.views._pull){
		const conf = view.views._pull[i];
		const one = [conf.x, conf.y, conf.type, conf.data, conf.config];
		if(config && config.viewIds)
			one.push(i);
		data.views.push(one);
	}
}

let thread;
function _trackAll(view){
	clearTimeout(thread);
	thread = webix.delay(() => {
		const ui = view.views;
		for(let id in ui._pull){
			const conf = ui._pull[id];

			const viewMethods = ui._types[conf.type];
			const render = viewMethods.render;
			const track = viewMethods.track;

			if(track)
				_trackOne(view, ui.get(id), track, conf, render);
		}
	});
}

function _trackOne(view, node, track, conf, render){
	const data = _fromRange(view, conf);

	if(conf.type == "chart"){
		if(render)
			node = render(node, conf, data, true);

		const config = conf.config;
		if(config && !_isPie(config.type))
			_removeLegend(data, conf, view);
	}

	track(node, data);
}

function _updateData(view, name, inc, data, start){
	const views = data.views;
	for(let i = 0; i < views.length; i++){
		if(_isRange(views[i][3])){
			const series = views[i][4].series;
			if(series){
				series.forEach(obj => {
					obj.range = changeRange(obj.range, name, inc, start);
					obj.active = changeRange(obj.active, name, inc, start);
				});
			}

			const legend = views[i][4].legend && views[i][4].legend.range;
			if(legend)
				views[i][4].legend.range = _getDataParts(legend).map(range => changeRange(range, name, inc, start)).join(",");

			const xAxis = views[i][4].xAxis && views[i][4].xAxis.range;
			if(xAxis)
				views[i][4].xAxis.range = changeRange(xAxis, name, inc, start);

			views[i][3] = _getDataParts(views[i][3]).map(range => changeRange(range, name, inc, start)).join(",");
		}
	}
}

function _showAll(view, pos){
	//close possibly open menus of embedded ui
	webix.callEvent("onClick", []);

	for(let id in view.views._pull){
		const win = webix.$$(id);
		const conf = view.views._pull[id];
		if(pos){
			conf.x = _correctPos(view, win, conf.x, "x");
			conf.y = _correctPos(view, win, conf.y, "y");
		}
		_showOne(view, win, conf);
	}
}

function _showOne(view, win, conf){
	const state = view._table.getScrollState();

	win.show({
		x:conf.x,
		y:conf.y-state.y
	});
}

function _correctPos(view, win, v, type){
	if(!v && v !==0 )
		v = view._table.getScrollState()[type]+50;
	else{
		const size = type == "x" ? _getWidth(view)-win.$width : _getHeight(view)-win.$height;
		v = Math.min(Math.max(v, 0), size);
	}	
	return v;
}

function _reset(view){
	ignoreUndo(function(){
		for(let id in view.views._pull)
			view.views.remove(id);
	}, view);
}

function _undoUI(view, move, id, value){
	if(value){
		const {x, y, type, data, config} = value;
		if(view.views._pull[id]){
			if(move)
				view.views.move(id, x, y);
			else
				view.views.update(id, data, config);
		}
		else
			view.views.add(x, y, type, data, config, id);

		const editor = view.$$("chartEditor");
		if(editor && editor.viewId == id)
			editor.queryView("form").setValues(config);
	}
	else
		view.views.remove(id);
}

function _getWidth(view){
	let width = 0;
	view._table.eachColumn(function(id){
		if(id !== "rowId")
			width += this.getColumnConfig(id).width;
	});
	return width;
}

function _getHeight(view){
	let height = 0;
	view._table.data.each(function(obj){
		height += obj.$height || webix.skin.$active.rowHeight;
	});
	return height;
}

//disable datatables not to interfere with ss table events
function _processTables(node){
	const query = function(view){
		return view.name == "datatable" || view.name == "treetable";
	};
	let tables = node.queryView(query, "self");
	if(tables) tables = [tables];
	else tables = node.queryView(query, "all");
	tables.forEach((dt) => dt.disable());
}

/* chart-specific helpers */

function _prepareType(config){
	const type = config.type;
	if(config.stacked && type.indexOf("stacked") == -1)
		config.type = "stacked"+config.type.charAt(0).toUpperCase()+config.type.substr(1);
	if(config["3D"] && type.indexOf("3D") == -1)
		config.type += "3D";
	if(config.horizontal && type.indexOf("H") == -1)
		config.type += "H";

	return config.type;
}

function _genChartConfig(conf, data, dataRange){
	const htmlFilter = /<[^>]*>/gi;
	let config = webix.copy(conf.config);

	config = _normalizeConfig(config);

	const scale = config.scale.lines;
	const scaleColor = config.scale.color || undefined;
	const type = (config.type = _prepareType(config));
	const pie = _isPie(type);

	const lines = config.series;
	config.series = [];
	const xAxis = config.xAxis;

	const legendConfig = config.legend;
	let pieLegend;
	let legendRange;

	const legend = {
		height:24,
		align: legendConfig.align || "center",
		valign: legendConfig.valign || "bottom",
	};
	legend.layout = legend.valign == "middle" ? "y" : "x";

	if(pie){
		if(legendConfig.fromData)
			pieLegend = "data0";
		else if(legendConfig.range)
			pieLegend = dataRange[legendConfig.range];

		legend.template = `#${pieLegend}#`;
	}
	else{
		legend.values = [];

		legendRange = !legendConfig.fromData && legendConfig.range;
		if(legendRange){
			const switchDirection = config.dataSeries == "rows";
			legendRange = _getParts(legendConfig.range, switchDirection, dataRange);
		}

		config.yAxis = config.yAxis||{};
		for(let property in config.yAxis)
			if(config.yAxis[property] === "")
				delete config.yAxis[property];

		config.yAxis = webix.extend(config.yAxis, {
			lineColor: scaleColor,
			color: scaleColor,
			lines: scale,
			lineShape: config.scale.circle ? "arc" : "default"
		});

		if(xAxis.fromData)
			xAxis.range = legendRange ? Object.keys(data[0]).filter(line => legendRange.indexOf(line) == -1)[0] : "data0";
		else if(xAxis.range)
			xAxis.range = dataRange[xAxis.range];
	}	

	for(let i = 0; i < lines.length; i++){
		const color = lines[i].color;
		const item = dataRange[lines[i].range];

		if(item != xAxis.range && !(pie && item == pieLegend /*(item == pieLegend || legendConfig.range == lines[i].active)*/)){
			const s = {
				value:`#${item}#`,
				tooltip:{
					template: lines[i].tooltip ? `#${item}#` : ""
				}
			};

			s[pie ? "pieInnerText" : "label"] = lines[i].label ? `#${item}#` : "";

			if(type == "radar" || type == "line" || type == "spline"){
				s.line = { color };
				s.item = { borderColor:color, type:lines[i].marker };
			}
			else if(!pie)
				s.color = color;

			if(type == "area" || type == "splineArea")
				s.alpha = 0.7;

			if(data[0] && !pie && (legendRange || legendConfig.fromData)){
				const val = legendRange ? data[0][legendRange[i]] : data[0][item];
				const text = (val || val === 0) ? (val+"").replace(htmlFilter, "") : "";
				legend.values.push({text, color});
			}

			config.series.push(s);
		}
	}

	if(!pie){
		config.xAxis = {
			title: config.xAxis.title,
			lineColor: scaleColor,
			color: scaleColor,
			lines: scale
		};

		config[type == "barH" || type == "stackedBarH" ? "yAxis" : "xAxis"].template = obj => {
			const val = obj[xAxis.range];
			return (val || val === 0) ? (val+"").replace(htmlFilter, "") : "";
		};
	}

	if(pieLegend || (legend.values && legend.values.length))
		config.legend = legend;
	else
		delete config.legend;

	return webix.extend({
		view:"chart",
		padding:{
			left:80
		}
	}, config, true);
}

function _isRange(data){
	return typeof data == "string" && data.indexOf("data:image") === -1;
}

function _fromRange(view, conf){
	let data = conf.data;

	if(/^(http|data:image|\/)/i.test(data))
		return data;

	const arr = [];
	if(data){
		const config = conf.config;
		const switchDirection = config.dataSeries == "rows";
		let lastIndex = 0;

		data = _getDataParts(data);
		for(let i = 0; i < data.length; i++){
			const cells = _isRange(data[i]) ? range(data[i], view) : null;
			if(cells){
				const page = cells[4] ? view._mData.getPage(cells[4]) : view._mPage;
				cells[5] = lastIndex;

				if(page){
					const subdata = switchDirection ? getRangeRows.apply(page, cells) : getRangeCols.apply(page, cells);
					lastIndex += subdata[2];
					for(let i = 0; i < subdata[0].length; i++){
						if(!arr[i])
							arr[i] = {};
						webix.extend(arr[i], subdata[0][i]);
					}
				}
			}
		}
	}
	return arr;
}

function _removeLegend(arr, conf, view){
	const config = conf.config;
	if(typeof(config.legend) != "object")
		config.legend = {fromData: !!config.legend};

	const legend = config.legend;

	if(legend.fromData)
		arr.shift();
	else if(legend.range){
		const names = _getNames(view, conf);
		const switchDirection = config.dataSeries == "rows";
		const legendRange = _getParts(legend.range, switchDirection, names);
		for (let i = 0; i < legendRange.length; i++){
			const item = legendRange[i];
			delete arr[0][item];
		}
	}
}

function _getNames(view, conf){
	let data = conf.data;

	if(/^(http|data:image|\/)/i.test(data))
		return;

	let names = {};
	if(data){
		const config = conf.config;
		const switchDirection = config.dataSeries == "rows";
		let lastIndex = 0;

		data = _getDataParts(data);
		for(let i = 0; i < data.length; i++){
			const cells = _isRange(data[i]) ? range(data[i], view) : null;
			if(cells){
				const page = cells[4] ? view._mData.getPage(cells[4]) : view._mPage;
				cells[5] = lastIndex;

				if(page){
					const subdata = switchDirection ? getRangeRows.apply(page, cells) : getRangeCols.apply(page, cells);
					lastIndex += subdata[2];
					webix.extend(names, subdata[1]);
				}
			}
		}
	}
	return names;
}

export function _getDataParts(data){
	return data.replace(/\s/g, "").split(",");
}

export function _isPie(type){
	return /pie|donut/.test(type);
}

export function _getSeries(view, data, dataSeries, active, oldLeftColumn){
	const dir = dataSeries == "rows";
	let vals = [];

	if(data){
		let xAxis = [];
		let legend = [];
		let activeVals = [];

		if(active){
			if(active.legend)
				legend = _getParts(active.legend, dir);
			if(active.xAxis)
				xAxis = _getParts(active.xAxis, dir);
			if(active.series)
				activeVals = active.series.filter(val => legend.indexOf(val.range) == -1 && xAxis.indexOf(val.range) == -1);
		}

		data = _getDataParts(data);
		let colorIndex = 0;
		let firstRange = 1;
		data.forEach(subdata => {
			const series = isRange(subdata) ? range(subdata, view) : null;
			if(series){
				const start = dir ? series[0] : series[1];
				const end = dir ? series[2] : series[3];

				let inActiveSeries;
				for(let i = start; i <= end; i++){
					const range = dir ? toRange(i, series[1], i, series[3], series[4]) : toRange(series[0], i, series[2], i, series[4]);
					const seria = arr.find(activeVals, val => val.range == range && (!val.active || val.active == subdata));

					if(seria){
						if(!seria.active)
							seria.active = subdata;
						inActiveSeries = 1;
					}
				}

				for(let i = start; i <= end; i++){
					const range = dir ? toRange(i, series[1], i, series[3], series[4]) : toRange(series[0], i, series[2], i, series[4]);
					if(legend.indexOf(range) != -1 || xAxis.indexOf(range) != -1)
						continue;

					const activeVal = activeVals.filter(val => val.range == range && val.active == subdata)[0];

					if(inActiveSeries && !activeVal && !(oldLeftColumn && firstRange && i == start))
						continue;

					vals.push(activeVal || {
						active: subdata,
						range,
						marker: "square",
						tooltip: 1,
						color: _getColor(colorIndex)
					});

					colorIndex++;
				}
				firstRange = 0;
			}
		});
	}
	return vals;
}

export function _getParts(ranges, dir, dataRange){
	const parts = [];

	ranges = _getDataParts(ranges);
	ranges.forEach(subRange => {
		subRange = isRange(subRange) ? range(subRange) : null;
		if(subRange){
			const start = dir ? subRange[0] : subRange[1];
			const end = dir ? subRange[2] : subRange[3];

			for(let i = start; i <= end; i++){
				const range = dir ?
					toRange(i, subRange[1], i, subRange[3], subRange[4]):
					toRange(subRange[0], i, subRange[2], i, subRange[4]);

				parts.push(dataRange ? dataRange[range] : range);
			}
		}
	});

	return parts;
}

export function _normalizeConfig(config){
	if(typeof(config.xAxis) != "object")
		config.xAxis = {fromData: !!config.xAxis};

	if(typeof(config.legend) != "object")
		config.legend = {fromData: !!config.legend};

	config.legend = webix.extend(config.legend, {
		layout:"x",
		align:"center",
		valign:"bottom"
	});

	if(!config.scale)
		config.scale = {
			lines:1
		};

	return config;
}

function _getColor(index){
	const colors = [
		"#e06666", "#6aa84f", "#3c78d8", "#e69138", "#783f04", "#134f5c", "#674ea7", "#c27ba0"
	];
	return colors[index%colors.length];
}

function getRangeCols(r1,c1,r2,c2,sheetName,lastIndex){
	const data = [];
	const rangeToData = {};
	for (let i = r1, rind = 0; i <= r2; i++, rind++){
		data[rind] = {};
		for (let j = c1, cind = lastIndex; j <= c2; j++, cind++){
			if(i == r2){
				rangeToData[`${sheetName ? sheetName+"!" : ""}${encode[j]}${r1}:${encode[j]}${r2}`] = `data${cind}`;
				lastIndex++;
			}
			data[rind][`data${cind}`] = this.getValue(i-1,j-1);
		}
	}
	return [data, rangeToData, lastIndex];
}

function getRangeRows(r1,c1,r2,c2,sheetName,lastIndex){
	const data = [];
	const rangeToData = {};
	for (let i = c1, cind = 0; i <= c2; i++, cind++){
		data[cind] = {};
		for (let j = r1, rind = lastIndex; j <= r2; j++, rind++){
			if(i == c2){
				rangeToData[`${sheetName ? sheetName+"!" : ""}${encode[c1]}${j}:${encode[c2]}${j}`] = `data${rind}`;
				lastIndex++;
			}
			data[cind][`data${rind}`] = this.getValue(j-1,i-1);
		}
	}
	return [data, rangeToData, lastIndex];
}