import * as imc from "./imagecell";
import * as imt from "./imagetop";
import * as asw from "./sparkline";
import * as rgs from "./ranges";
import * as dpd from "./dropdown";
import * as flt from "./filter";
import * as fmt from "./format";
import * as cnd from "./conditional";
import * as exp from "./export";
import * as lnk from "./link";
import * as pnt from "./print";
import * as cmt from "./comments";
import * as siz from "./sizing";

const bases = [ imt, imc, asw, rgs, dpd, flt, fmt, cnd, exp, lnk, pnt, cmt, siz ];

export function init(view){
	const dialogs = {};
	const viewBases = view.config.readonly ? [cmt, exp, pnt] : bases;

	for (let i = 0; i < viewBases.length; i++)
		dialogs[viewBases[i].action] = new viewBases[i].DialogBox(view);

	view.attachEvent("onCommand", function(action){
		const box = dialogs[action.id];
		if (box) box.open(action);
	});

	view.attachEvent("onAction", function(id, values){
		//hide win when the external ui is removed
		if(id == "add-view" && !values.newValue){
			const box = dialogs["add-image-top"];
			if(box && box.viewid == values.row){
				box.close();
			}
		}
	});
}