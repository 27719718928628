import {methods} from "@xbs/muon";

let methodsName = [];
for(let name in methods)
	methodsName.push(name);

export function init(view){
	view.attachEvent("onComponentInit", () => ready(view));

	return {
		view:"toolbar",
		css:"webix_ssheet_toolbar",
		elements:[
			{
				view:"text",
				icon: "wxi-menu-down",
				on: {
					onItemClick: function(){
						const suggest = webix.$$(this.config.suggest);
						if(!suggest.isVisible())
							suggest.show(this.getInputNode());
					},
					onChange: (val, oldVal, config) => {
						if(config == "user" && val !== "")
							view.showCell(val);
					}
				},
				suggest: {
					view:"ssheet-suggest-labels",
					css: "webix_ssheet_nav_suggest",
					body: {
						template: "<span class='webix_ssheet_left'>#name#</span><span class='webix_ssheet_right'>#value#</span>",
						width: 250
					},
					on:{
						onBeforeShow: function(){
							const data = view.ranges.serialize(view.getActiveSheet()).map(r => ({name: r[0], value: r[1]}));

							if(!data.length)
								return false;

							const list = this.getList();
							list.clearAll();
							list.parse(data);
						}
					},
					data: []
				},
				width: 150,
				id: "navigation"
			},
			{
				view: "ssheet-separator"
			},
			{
				view:"label",
				label:webix.i18n.spreadsheet.liveEditor["edit"],
				width:65
			},
			{
				view:"live-editor",
				disabled:true,
				id:"liveEditor",
				suggestData: methodsName
			}
		]
	};
}

function ready(view){
	const editor = view.$$("liveEditor");
	const nav = view.$$("navigation");
	const grid = view._table;

	//block native editor, and move focus to the custom input
	//edit by key press
	grid.attachEvent("onBeforeEditStart", function(cell){
		const mode = grid.$anyKey;
		grid.$anyKey = false;

		return startEdit(this, cell, mode);
	});
	//edit by enter key
	grid.attachEvent("onBeforeLiveEditor", function(cell){
		return startEdit(this, cell, false);
	});
	//edit by dbl-click
	grid.attachEvent("onItemDblClick", function(cell){
		if(view.getCellEditor(cell.row, cell.column))
			this.edit(cell);
		else
			startEdit(this, cell, false);
	});

	grid.attachEvent("onAfterScroll", function(){
		if(editor.$view.contains(document.activeElement))
			editor.paintValue();
	});

	view.attachEvent("onCellChange", (r,c,v,p) => {
		if(p == view.getActiveSheet()){
			const cell = editor.config.activeCell;
			if (cell && cell.row == r && cell.column == c)
				editor.setValue(v);
		}
	});

	view.attachEvent("onAfterSelect", data => {
		if (!view.$handleSelection)
			fillEditor(data[0]);
	});

	view.attachEvent("onChange", mode => {
		if (mode == "update" && editor.isEnabled()){
			const cell = editor.config.activeCell;
			if(cell && editor.getValue() != view.getCellValue(cell.row, cell.column)){
				delete editor._update_range;
				editor.showActiveSheet();
				editor.updateCellValue();
			}
		}
	});

	view.attachEvent("onBeforeSheetShow", name => {
		if(view._rangeDialog)
			return;
		const cell = editor.config.activeCell;
		if(cell){
			const cursor = editor.getInputNode().selectionStart;
			const val = editor.getValue();
			if(view.getCellValue(cell.row, cell.column) != val){
				if(!editor._activeMath)
					editor._activeMath = view.getActiveSheet();
				webix.delay(()=>{
					if(name == editor._activeMath){
						view.$handleSelection = null;
						const cell = editor.config.activeCell;
						grid.select(cell.row, cell.column);
					}

					editor.setValue(val);
					//update highlight
					editor.refresh();

					editor.getInputNode().setSelectionRange(cursor, cursor);
					editor.paintValue();

					view.$handleSelection = function(a,b,st,en){ return pasteRange(st, en, a); };
				});
			}
			else{
				nav.setValue("");
				clearEditor();
			}
		}
	});

	view.attachEvent("onReset", () => {
		if(!view._rangeDialog)
			view.$handleSelection = null;
	});
	view.$$("cells").attachEvent("onFocus", ()=>{
		//ignore table clipboard logic
		if(editor._activeMath){
			webix.delay(()=>editor.focus());
		}
	});

	view.attachEvent("onAfterLoad", () => clearEditor());

	view.attachEvent("onAction", function(name, options){
		const cell = editor.config.activeCell;
		const isActiveCell = cell && cell.row == options.row && cell.column == options.column;
		if(isActiveCell && (name == "lock" || name == "dropdown"))
			disableEditor(options.row, options.column);
	});

	view.attachEvent("onFormatChange", (r, c) => updateEditor(r, c));
	view.attachEvent("onCellChange", (r, c, v , p) => {
		if(p == view.getActiveSheet())
			updateEditor(r, c);
	});

	function updateEditor(row, column){
		const activeCell = editor.config.activeCell;
		if(activeCell && activeCell.row == row && activeCell.column == column)
			fillEditor({row, column});
	}

	function startEdit(table, cell, clear){
		//do not interfere with custom editors
		if (!view.getCellEditor(cell.row, cell.column) && fillEditor(cell, clear)){
			editor.focus();

			if(!clear){
				editor.paintValue();

				const input = editor.getInputNode();
				const symbols = input.value.length;
				input.setSelectionRange(symbols, symbols);
			}
			return false;
		}
		return true;
	}

	function disableEditor(row,column){
		const disabled = view.getCellEditor(row, column) || view.isCellLocked(row, column);
		if(disabled)
			editor.disable();
		else
			editor.enable();
		return disabled;
	}

	function fillEditor(cell, clear){
		const disabled = disableEditor(cell.row, cell.column);
		editor.config.activeCell = cell;
		editor.setValue( clear ? "" : editor.getCellValue(cell.row, cell.column) );

		if(!view._rangeDialog)
			view.$handleSelection = disabled ? null : function(a,b,st,en){ return pasteRange(st, en, a); };

		return !disabled;
	}

	function pasteRange(st, en, cell){
		const updateRange = editor._update_range;
		let cursor = editor.getInputNode().selectionStart;

		let sheet = "";
		if(editor._activeMath){
			const activeSheet = view.getActiveSheet();
			if(editor._activeMath != activeSheet)
				sheet = editor.prepareSheet(activeSheet);
		}

		let range;
		if(st == en){
			const colon = editor.getValue()[ (updateRange ? updateRange.pos : cursor)-1 ] == ":";
			range = colon ? st : sheet+st;
		}
		else
			range = `${sheet+st}:${en}`;

		if(updateRange && updateRange.pos == cursor - updateRange.len){
			editor.setRange(range, true);
			cursor = editor._update_range.pos + range.length;
		}
		else if(editor.expectOperator()){
			editor.setRange(range);
			cursor += range.length;
		}
		else
			return endEdit(cell);

		editor.focus();
		editor.getInputNode().setSelectionRange(cursor, cursor);
		editor.paintValue();

		return false;
	}

	function endEdit(st){
		if (editor.isEnabled()){
			const cell = editor.config.activeCell;
			editor.showActiveSheet();
			editor.updateCellValue(cell);
			editor.setValue(st ? view.getCellValue(st.row, st.column) : "");
		}
		return true;
	}

	function clearEditor(){
		editor.define({activeCell:null});
		editor.setValue("");
		editor.disable();
	}
}
