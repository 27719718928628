import group from "./undo_group";
import {save} from "./save";
import {eachRange} from "../helpers/column_names";
import {defaultStyles} from "../toolbar/defaults";

//do not change order, it will break server side compatibility
//add new options to the end of this list
export const style_names = [
	"color","background","text-align",
	"font-family","font-size",
	"font-style","underline","font-weight",
	"vertical-align", "wrap", "borders", "format",
	"border-right", "border-bottom","border-left", "border-top",
	"strike", "indent"
];

const _style_map = {
	format: ()=> "",
	"text-align": {
		left: () => "justify-content:flex-start;",
		center: () => "justify-content:center;text-align:center;",
		right: () => "justify-content:flex-end;text-align:right;"
	},
	"vertical-align": {
		top: () => "align-items:flex-start;",
		middle: () => "align-items:center;",
		bottom: () => "align-items:flex-end;"
	},
	wrap: {
		wrap: ()=> {
			return "white-space: normal !important;";
		}
	},
	"border-left": obj => {
		return getBorderCss("border-left", obj);
	},
	"border-top": obj => {
		return getBorderCss("border-top", obj);
	},
	"border-right": obj => {
		return getBorderCss("border-right", obj);
	},
	"border-bottom": obj => {
		return getBorderCss("border-bottom", obj);
	},
	"font-weight": obj => {
		//Roboto bold - font-weight:500 (looks better, than 700). Roboto is default font for material skins.
		if(obj["font-weight"] == "bold"){
			const family = obj["font-family"] || defaultStyles["font-family"];
			return "font-weight:" + (family == "'Roboto', sans-serif" ? 500 : 700) + ";";
		}
		return "";
	},
	underline: obj => {
		const underline = obj.underline == "underline" ? "underline" : "";
		if(underline){
			const strike = obj.strike == "strike" ? "line-through" : "";
			return `text-decoration: ${underline} ${strike};`;
		}
		return "";
	},
	strike: obj => {
		if(obj.underline != "underline" && obj.strike == "strike")
			return "text-decoration: line-through;";
		return "";
	},
	indent: obj => {
		const count = obj.indent * 1;
		if(count > 0){
			const pos = obj["text-align"] == "right" ? "right" : "left";
			return { innerCss: `margin-${pos}:${count * 8 + 12}px;` }; // 12 - default cell margin
		}
	}
};

function getBorderCss(pos, obj){
	let border = obj[pos];
	if(border){
		let [color, type] = border.split(",");

		type = type || "thin";
		let size = "1px";
		if(type == "medium")
			size = "2px";
		else if(type == "thick" || type == "double")
			size = "3px";

		if(["thin", "medium", "thick"].indexOf(type) != -1)
			type = "solid";

		return `${pos}: ${size} ${type} ${color} !important;`;
	}
	return "";
}

const border_checks = {
	"border-left":		function(cell, area, mode){ return cell.column == area.start.column || mode =="no"; },
	"border-right":		function(cell, area, mode){ return cell.column == area.end.column || mode == "all" || mode =="no"; },
	"border-top":		function(cell, area , mode){ return cell.row == area.start.row || mode =="no"; },
	"border-bottom":	function(cell, area, mode){ return cell.row == area.end.row || mode == "all"|| mode =="no"; }
};

const _style_handlers = {
	"borders": function(view, style, value, cell){
		const area = view.$$("cells").getSelectArea();
		value = value.split(",");
		const type = value[0];
		let color = value[1];
		let lineStyle = value[2];

		var modes = ["border-left", "border-right", "border-bottom", "border-top"];

		if(type == "top-bottom"){
			modes = ["border-top","border-bottom"];
		}
		else if (type != "no" && type != "all" && type != "outer")
			modes = ["border-"+type];

		for (var i = 0; i < modes.length; i++){
			var mode = modes[i];
			let result = view.callEvent("onAction", ["check-borders", {row:cell.row, column:cell.column, area, type, mode}]);
			if (result === true || border_checks[mode](cell, area, type)){
				const border = type == "no" ? "" : `${color},${lineStyle}`;
				style = _updateStyle(view, style, mode, border, cell);
			}
		}
		return style;
	}
};

var sizeEl;
export function init(view){
	view.attachEvent("onStyleSet", (name, value) => _applyStyles(view, name, value));
	view.attachEvent("onDataParse", (data) => _parse(view, data));
	view.attachEvent("onDataSerialize", (data) => _serialize(view, data));
	view.attachEvent("onReset", () => reset(view));
	view.attachEvent("onUndo", (type, row, column, style) => {
		if(type == "style")
			_undoStyle(view, row, column, style);
	});

	reset(view);

	if (!sizeEl)
		sizeEl = webix.html.create("DIV", { style:"visibility:hidden; position:absolute; top:0px; left:0px; height:auto;"}, "");
}

function reset(view){
	view._styles = {};
	view._styles_cache = {};
	view._styles_max = 1;

	let prefix = ".wss_"+view.$index;
	webix.html.removeStyle(prefix);
}

export function getStyle(view, cell){
	const obj = view._mPage.getCell(cell.row-1, cell.column-1);
	const span = view.$$("cells").getSpan(cell.row, cell.column);

	if(span && span[0] == cell.row && span[1] == cell.column){
		const spanStyles = span[5].split(" ");
		for (let i = 0; i < spanStyles.length; i++){
			const spanStyle = view._styles[spanStyles[i]];
			if(spanStyle)
				return spanStyle;
		}
	}

	if (obj && obj.style)
		return view._styles[obj.style];

	return null;
}

export function getFormat(view, row, column){
	var obj = view._mPage.getCell(row-1, column-1);
	return (obj && obj.format) || "";
}

// undo
function _undoStyle(view, row, column, style){
	var cell = {row: row, column: column};
	setStyle(view, cell, style);
}

function _serialize(view, obj){
	var styles = [];

	for (var key in view._styles_cache)
		styles.push([view._styles_cache[key].id, key]);

	obj.styles = styles;
}

export function addStyle(view, props, origin){
	var style = {
		props: styleFromText( style_names.map(()=>"").join(";") )
	};

	if (origin)
		for (var key in origin.props)
			style.props[key] = origin.props[key];

	for (let  key in props)
		style.props[key] = props[key];


	style.text = styleToText(style);
	var cache = view._styles_cache[style.text];
	if (cache)
		return cache;

	_addStyle(view, style);

	return style;
}

function _parse(view, obj){
	if (obj.styles)
		for (let i=0; i < obj.styles.length; i++){
			var styleObj = obj.styles[i];
			var style = {
				id: styleObj[0],
				text:styleObj[1],
				props: styleFromText(styleObj[1])
			};

			_addStyle(view, style, true);
		}

	for (let i = 0; i < obj.data.length; i++){
		var [row, column, , css] = obj.data[i];

		if (css)
			update_style_data(view, row, column, view._styles[css]);
	}
}

function _applyStyles(view, name, value){
	group.set(function(){
		view.eachSelectedCell(function(cell){
			const span = view.$$("cells").getSpan(cell.row, cell.column);
			if(!span || (span && span[0] == cell.row && span[1] == cell.column))
				_applyCellStyles(view, cell, name, value);
		});
	});
	view.refresh();
}

function _applyCellStyles(view, cell, name, value){
	const ostyle = getStyle(view, cell);

	if(name == "indent"){
		const oldVal = ((ostyle && ostyle.props[name]) || 0) * 1;

		if(!oldVal && value == -1)
			return;

		value += oldVal;
	}

	const nstyle = _updateStyle(view, ostyle, name, value, cell);

	if (nstyle && nstyle != ostyle)
		_setStyle(view, cell, nstyle, ostyle, true);
}

function _updateStyle(view, style, name, value, cell){

	if(_style_handlers[name]){
		return _style_handlers[name](view, style, value, cell);
	}

	if (style && style.props[name] == value) return style;

	var nstyle = { text:"", id:0, props: (style?webix.copy(style.props):{}) };
	nstyle.props[name] = value;
	nstyle.text = styleToText(nstyle);

	var cache = view._styles_cache[nstyle.text];
	if (cache)
		return cache;

	_addStyle(view,nstyle);

	return nstyle;
}

function update_style_data(view, row, column, style){
	const obj = view._mPage.getCell(row-1, column-1, true);
	obj.style = style && style.id;
	obj.format = style && style.props.format;
}

export function setStyle(view, cell, style){
	const old = getStyle(view, cell);
	return _setStyle(view, cell, style, old);
}

export function setRangeStyle(view, range, style) {
	eachRange(range, view, _setStyle, style);
}

export function getTextSize(view, text, css, baseWidth){
	sizeEl.innerHTML = text;
	sizeEl.style.width = baseWidth ? baseWidth+"px" : "auto";

	sizeEl.className = "webix_table_cell webix_cell "+css;
	view._table.$view.appendChild(sizeEl);

	const width = Math.max(0, sizeEl.offsetWidth+1);
	const height = Math.max(0, sizeEl.offsetHeight+1);

	view._table.$view.removeChild(sizeEl);
	sizeEl.innerHTML = "";

	return { width, height };
}

function _setStyle(view, cell, style, old, toolbar){
	if(view.callEvent("onBeforeStyleChange", [cell.row, cell.column, style, old])){
		group.set(()=>{
			const format = style && style.props.format || null;
			const oldFormat = old && old.props.format || null;
			const formatChanged = format != oldFormat;
			if(!formatChanged || formatChanged && view.callEvent("onBeforeFormatChange", [cell.row, cell.column, format, oldFormat])){
				update_style_data(view, cell.row, cell.column, style);
				view.callEvent("onStyleChange", [cell.row, cell.column, style, old]);

				if(formatChanged)
					view.callEvent("onFormatChange", [cell.row, cell.column, format, oldFormat]);

				view.saveCell(cell.row,cell.column);

				const sel = view.getSelectedId();
				if(!toolbar && sel && sel.row == cell.row && sel.column == cell.column)
					view.callEvent("onCommand", [{id:"toolbar-update"}]);
			}
		});
	}
}

function _buildCssString(style) {
	let css = "";
	let innerCss = "";

	for (var key in style){
		if (style[key]){
			if( _style_map[key]){
				if(_style_map[key][style[key]])
					css += _style_map[key][style[key]](style);
				else if(typeof  _style_map[key] == "function"){
					const outCss = _style_map[key](style);

					if(typeof outCss == "object"){
						css += outCss.css || "";
						innerCss += outCss.innerCss || "";
					}
					else
						css += _style_map[key](style);
				}
			}
			else
				css+= key+":"+style[key]+";";
		}
	}

	return {css, innerCss};
}

function _addStyle(view, style, silent){
	view._styles_cache[style.text] = style;

	while (!style.id || view._styles[style.id])
		style.id = "wss"+(view._styles_max++);
	view._styles[style.id] = style;

	const {css, innerCss} = _buildCssString(style.props);
	const prefix = ".wss_"+view.$index;
	webix.html.addStyle(prefix+" ."+style.id+"{"+css+"}", prefix);
	webix.html.addStyle(prefix+" ."+style.id+" div:first-child{"+innerCss+"}", prefix);

	if (!silent)
		save(view, "styles", { name:style.id, text: style.text } );
}

export function styleToText(style){
	var id = [];
	for (var i=0; i<style_names.length; i++)
		id.push(style.props[style_names[i]]);
	return id.join(";");
}

export function styleFromText(text){
	var parts = text.split(";");
	var props = {};
	for (var i=0; i<style_names.length; i++)
		props[style_names[i]] = parts[i];
	return props;
}



export function clearRangeStyle(view, range){
	group.set(function(){
		eachRange(range, view, function(view, cell){
			let style = getStyle(view,cell);
			if (style)
				setStyle(view, cell, null);
		});
	});
}

export function compactStyles(view){
	const serialized = view.serialize();
	serialized.styles = removeUnusedStyles(serialized);

	reset(view);
	_parse(view, serialized);
}

export function removeUnusedStyles(serialized){
	const data = serialized.data;
	const styles = serialized.styles;

	const used = {};
	for (let i=0; i<data.length; i++){
		const name = data[i][3];
		if (name)
			used[name] = 1;
	}

	const newStyles = [];
	for(let i = 0; i<styles.length; i++){
		const name = styles[i][0];
		if (used[name])
			newStyles.push(styles[i]);
	}

	return newStyles;
}