import {Dialog} from "./common";

export const action = "excel-export";

export class DialogBox extends Dialog{
	$show(box, form){
		this.view.$handleSelection = function(){
			return false;
		};

		form.elements.sheets.setValue(this.getSheets());
		form.elements.filename.setValue("Data");
		form.elements.filename.getInputNode().select();
	}
	$hide(){
		this.view.$handleSelection = null;
	}
	$init(){
		return {
			view: "ssheet-dialog",
			head: webix.i18n.spreadsheet.labels["export-title"],
			move: true,
			position: "center",
			body:{
				view:"form",
				elements:[
					{ view:"text", name:"filename",  placeholder:webix.i18n.spreadsheet.labels["export-name"] },
					{ view:"multicheckbox", name:"sheets", css:"webix_ssheet_multicheckbox" }
				]
			},
			on:{
				onSaveClick: () => this.okClick(),
				onCancelClick: () => this.close()
			}
		};
	}
	getSheets(){
		const view = this.view;
		const sheets = view._sheets;
		let value = [];

		for(let i = 0; i < sheets.length; i++){
			const state = sheets[i].state;
			if(state == "veryHidden")
				continue;

			value.push({
				name: sheets[i].name,
				hidden: state == "hidden",
				active: view._activeSheet === sheets[i].name ? 1 : 0
			});
		}

		return value;
	}
	okClick(){
		const values = this.$dialog.getBody().getValues();
		webix.toExcel(this.view, values);
		this.close();
	}
}